<template>
  <div
    ref="sidepanelHeaderRef"
    class="z-module-layer-fix-1 flex w-full flex-nowrap items-center justify-between gap-2 rounded-t-xl border-b bg-white p-2">
    <div v-if="slotInUse.headerLeft" class="grow">
      <slot name="headerLeft"></slot>
    </div>

    <div class="flex items-center gap-2">
      <AKTooltip v-if="buttonMenuItems.length" :text="t('AKSidePanelHeader.action-menu.tooltip')">
        <AKIcon
          class="ak-side-panel-header-button"
          :framework-icon="PrimeIcons.ELLIPSIS_H"
          @click="akMenu?.toggleMenu" />
      </AKTooltip>

      <AKMenu ref="akMenu" :items="buttonMenuItems" />

      <slot name="headerAfterButtonMenu"></slot>

      <div v-if="sidePanelMaximized && providedHeaderData.showMinimizeButton" class="hidden lg:block">
        <AKTooltip :text="t('AKSidePanelHeader.action-button.minimize')">
          <AKIcon
            class="ak-side-panel-header-button"
            :framework-icon="PrimeIcons.WINDOW_MINIMIZE"
            @click="minimizeSidePanel()" />
        </AKTooltip>
      </div>
      <div v-if="!sidePanelMaximized && providedHeaderData.showMaximizeButton" class="hidden lg:block">
        <AKTooltip :text="t('AKSidePanelHeader.action-button.maximize')">
          <AKIcon
            class="ak-side-panel-header-button hidden sm:block"
            :framework-icon="PrimeIcons.WINDOW_MAXIMIZE"
            @click="maximizeSidePanel()" />
        </AKTooltip>
      </div>
      <div v-if="providedHeaderData.showCloseButton">
        <AKTooltip :text="t('AKSidePanelHeader.action-button.close')">
          <AKIcon class="ak-side-panel-header-button" :framework-icon="PrimeIcons.TIMES" @click="closeSidePanel()" />
        </AKTooltip>
      </div>
    </div>
  </div>

  <AKConfirmAction
    v-model="showConfirmDelete"
    :confirm-method="ValidConfirmMethods.INPUT_CONFIRM"
    group="sidePanelHeaderConfirmDeleteItem"
    :check-value="t('AKSidePanelHeader.confirm-delete-value')"
    :headline="t('AKSidePanelHeader.confirm-delete-headline')"
    @on-confirm="confirmDelete()" />
</template>

<script setup lang="ts">
import { AKSidePanelHeaderInjectedData, AKSidePanelState } from '@ak_tools/components/layout/';
import { AKIcon } from '@ak_tools/components/media';
import { AKMenu, AKMenuItem } from '@ak_tools/components/menu';
import { AKConfirmAction, AKTooltip, ValidConfirmMethods } from '@ak_tools/components/overlay';
import { AKStore } from '@ak_tools/start/App';
import { AKAppUIScrollingStates, AKBreakpoints } from '@ak_tools/store/app/types';
import { PrimeIcons } from 'primevue/api';
import { computed, inject, Ref, ref, useSlots, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const sidePanelState = ref<AKSidePanelState>(AKSidePanelState.MINIMIZED);
const showConfirmDelete = ref(false);
const akMenu = ref<InstanceType<typeof AKMenu> | null>(null);
const slotInUse = useSlots();

const sidepanelHeaderRef = ref<HTMLElement | null>(null);

const providedHeaderData = inject('providedHeaderData') as Ref<AKSidePanelHeaderInjectedData>;

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const sidePanelMaximized = computed(() => {
  return sidePanelState.value === AKSidePanelState.MAXIMIZED;
});

const buttonMenuItems = computed(() => {
  const items: AKMenuItem[] = [];

  if (providedHeaderData.value.showCopyLocationButton) {
    const linkButtonItem: AKMenuItem = {
      label: t('AKSidePanelHeader.action-menu.link'),
      textClass: 'text-gray-500',
      icon: PrimeIcons.LINK,
      shortcut: 'Alt + Ctrl + C',
      command: () => navigator.clipboard.writeText(window.location.href),
    };

    items.push(linkButtonItem);
  }

  if (providedHeaderData.value.showArchiveButton) {
    const archiveButtonItem: AKMenuItem = {
      label: providedHeaderData.value.itemIsArchived
        ? t('AKSidePanelHeader.action-menu.unarchive')
        : t('AKSidePanelHeader.action-menu.archive'),
      textClass: 'text-gray-500',
      icon: PrimeIcons.INBOX,
      shortcut: 'Alt + Ctrl + A',
      command: () => emitArchiveItem(),
    };

    items.push(archiveButtonItem);
  }

  if (providedHeaderData.value.showHistoryButton) {
    const historyButtonItem: AKMenuItem = {
      label: t('AKSidePanelHeader.action-menu.history'),
      textClass: 'text-gray-500',
      icon: PrimeIcons.HISTORY,
      shortcut: 'Alt + Ctrl + H',
      command: () => emitHistoryItem(),
    };

    items.push(historyButtonItem);
  }

  if (providedHeaderData.value.showDuplicateButton) {
    const duplicateButtonItem: AKMenuItem = {
      label: t('AKSidePanelHeader.action-menu.duplicate'),
      textClass: 'text-gray-500',
      icon: PrimeIcons.COPY,
      shortcut: 'Alt + Ctrl + V',
      command: () => emitDuplicateItem(),
    };

    items.push(duplicateButtonItem);
  }

  if (providedHeaderData.value.showPrintButton) {
    const printButtonItem: AKMenuItem = {
      label: t('AKSidePanelHeader.action-menu.print'),
      textClass: 'text-gray-500',
      icon: PrimeIcons.PRINT,
      shortcut: 'Alt + Ctrl + P',
      command: () => emitPrintItem(),
    };
    items.push(printButtonItem);
  }

  if (providedHeaderData.value.showDeleteButton) {
    const deleteButtonItem: AKMenuItem = {
      label: t('AKSidePanelHeader.action-menu.delete'),
      textClass: 'text-red-500',
      icon: PrimeIcons.TRASH,
      shortcut: 'Alt + Ctrl + D',
      command: () => deleteItem(),
    };
    items.push(deleteButtonItem);
  }

  return items;
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const maximizeSidePanel = () => {
  sidePanelState.value = AKSidePanelState.MAXIMIZED;
  emitMaximizeSidePanel();
};

const minimizeSidePanel = () => {
  sidePanelState.value = AKSidePanelState.MINIMIZED;
  emitMinimizeSidePanel();
};

const closeSidePanel = () => {
  emitCloseSidePanel();
};

const deleteItem = () => {
  showConfirmDelete.value = true;
};

const confirmDelete = () => {
  emitDeleteItem();
};

const currentBreakpoint = computed(() => {
  return AKStore.App.state.breakpoint;
});

const currentScrollingState = computed(() => {
  return AKStore.App.state.uiStates.out.sidePanelScrollingState;
});

watch(currentScrollingState, (newState) => {
  if (sidepanelHeaderRef.value && currentBreakpoint.value <= AKBreakpoints.SM) {
    switch (newState) {
      case AKAppUIScrollingStates.UP:
        sidepanelHeaderRef.value.style.marginTop = '0px';
        break;

      case AKAppUIScrollingStates.DOWN:
        const headerHeight = sidepanelHeaderRef.value.offsetHeight || 0;
        sidepanelHeaderRef.value.style.marginTop = `-${headerHeight}px`;
        break;

      default:
    }
  }
});

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits([
  'closeSidePanel',
  'minimizeSidePanel',
  'maximizeSidePanel',
  'deleteItem',
  'archiveItem',
  'historyItem',
  'duplicateItem',
  'printItem',
]);

const emitCloseSidePanel = () => {
  emits('closeSidePanel');
};

const emitMinimizeSidePanel = () => {
  emits('minimizeSidePanel');
};

const emitMaximizeSidePanel = () => {
  emits('maximizeSidePanel');
};

const emitDeleteItem = () => {
  emits('deleteItem');
};

const emitArchiveItem = () => {
  emits('archiveItem');
};

const emitHistoryItem = () => {
  emits('historyItem');
};

const emitDuplicateItem = () => {
  emits('duplicateItem');
};

const emitPrintItem = () => {
  emits('printItem');
};
</script>

<style lang="sass">
.ak-side-panel-header-button
  @apply cursor-pointer rounded p-2 text-gray-400 hover:bg-gray-200 hover:text-gray-500
</style>
