<template>
  <span class="icon-wrapper">
    <i
      v-if="!props.akIcon && !props.customTemplate"
      :class="[frameworkIconClass, props.iconClasses, computedHoverClass]"
      :style="`font-size: ${computedSize}rem; font-weight: ${props.bold ? 700 : 400}`" />
    <div v-else-if="props.customTemplate">
      <slot></slot>
    </div>
    <i
      v-else
      class="flex aspect-square text-center"
      :class="[props.iconClasses, computedHoverClass]"
      :style="`width: ${computedSize}rem`"
      v-html="props.akIcon" />
  </span>
</template>
<script setup lang="ts">
type _VTI_TYPE_AKSVGIcons = /* enum */ string
interface PropsInterface {
  frameworkIcon?: string;
  akIcon?: _VTI_TYPE_AKSVGIcons;
  scale?: number | 'auto';
  iconClasses?: string;
  bold?: boolean;
  customTemplate?: boolean;
  hoverEffect?: boolean;
}
import { AKSVGIcons } from '@ak_tools/ts_modules/graphics/AKSVGIcons'
import { computed } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  frameworkIcon: undefined,
  akIcon: undefined,
  scale: 1,
  iconClasses: undefined,
  bold: false,
  customTemplate: undefined,
  hoverEffect: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const defaultDimension = 1.25;
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const computedSize = computed(() => {
  if (props.scale === 'auto') {
    return 'auto';
  } else {
    return defaultDimension * props.scale;
  }
});
const computedHoverClass = computed(() => {
  if (props.hoverEffect) {
    return 'ak-hover';
  } else {
    return '';
  }
});
const frameworkIconClass = computed(() => {
  return !props.akIcon && props.frameworkIcon ? props.frameworkIcon : '';
});
</script>

<style lang="sass" scoped>
@import '@ak_tools/assets/ak_styles/variables/default/_ak_colors.sass'

.ak-hover
  @apply inline-flex items-center justify-center hover:brightness-75 transition-[filter] duration-200 ease-in-out
</style>
