<template>
  <AKModuleHeader>
    <slot name="moduleHeaderActions"></slot>
  </AKModuleHeader>

  <div class="m-2 !mt-0 flex h-full flex-col overflow-hidden sm:m-4">
    <div class="flex max-h-12">
      <AKTabs>
        <AKTab v-model:active="formIsActive" :title="t('AKSupportViewContainer.form-tab.title')" />
        <AKTab
          v-if="slotInUse.faq"
          v-model:active="faqIsActive"
          :title="t('AKSupportViewContainer.faq-tab.title')"></AKTab>
        <AKTab
          v-if="slotInUse.history"
          v-model:active="historyIsActive"
          :title="t('AKSupportViewContainer.history-tab.title')"></AKTab>
        <AKTab v-model:active="afterConceptIsActive" :title="t('AKSupportViewContainer.concept-tab.title')"></AKTab>
      </AKTabs>
    </div>
    <div class="flex grow flex-col overflow-x-hidden rounded-b-xl bg-white p-4">
      <div v-if="formIsActive" class="ak-flex-col grow">
        <iframe
          ref="asanaIframe"
          :key="refreshAsanaIframe"
          class="asana-embed-iframe"
          title="Support-Formular"
          :src="FormularUrl"
          style="height: 93%; display: block" />
        <AKButton class="w-full" :label="t('AKSupportVueContainer.refresh-button.label')" @click="reloadIframe" />
      </div>
      <div v-if="faqIsActive" class="grow">
        <slot name="faq"></slot>
      </div>
      <div v-if="historyIsActive" class="grow">
        <slot name="history"></slot>
      </div>
      <div v-if="afterConceptIsActive" class="grow">
        <img
          class="img-fluid mt-2"
          alt="Support-Konzept"
          :src="supportImageUrl"
          @error="tryToDisplayImageInDebugMode" />
        <ul class="mt-2">
          <li>E-Mail: <a href="mailto:wapp-support@abus-kransysteme.de">wapp-support@abus-kransysteme.de</a></li>
          <li>Telefon: <a href="tel:+492261376230">02261 376230</a></li>
        </ul>
        <slot name="afterConcept"></slot>
      </div>
    </div>
  </div>

  <link href="https://form.asana.com/static/asana-form-embed-style.css" rel="stylesheet" />
</template>

<script setup lang="ts">
import { AKButton } from '@ak_tools/components/button';
import { AKModuleHeader } from '@ak_tools/components/layout';
import { AKTab, AKTabs } from '@ak_tools/components/panel';
import AKEnv from '@ak_tools/framework_support/AKEnv';
import { AKStore } from '@ak_tools/start/App';
import { computed, ref, useSlots, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t, locale } = useI18n();

//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const activeDisplayMode = defineModel<AKDisplaySupportMode>('activeDisplayMode', {
  default: AKDisplaySupportMode.FORMULAR,
});

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const formIsActive = ref(false);
const faqIsActive = ref(false);
const historyIsActive = ref(false);
const afterConceptIsActive = ref(false);
const route = useRoute();
const slotInUse = useSlots();
const supportImageUrl = `${AKEnv.VUE_APP_ROOT_URL_PATH}Supportkonzept.drawio.png`;
const supportImageUrlDebug = 'Supportkonzept.drawio.png';
const FormularUrl = ref('https://form.asana.com/?k=ueGaUfQfR-grdI8HYlO3Zw&d=1199942936521883&embed=true');
const refreshAsanaIframe = ref(0);
const triedToDisplayImageInDebugMode = ref(false);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const computedFormularIsActive = computed(() => {
  return activeDisplayMode.value === AKDisplaySupportMode.FORMULAR;
});

const computedFAQIsActive = computed(() => {
  return activeDisplayMode.value === AKDisplaySupportMode.FAQ;
});

const computedHistoryIsActive = computed(() => {
  return activeDisplayMode.value === AKDisplaySupportMode.HISTORY;
});

const computedAfterConceptIsActive = computed(() => {
  return activeDisplayMode.value === AKDisplaySupportMode.AFTERCONCEPT;
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
function checkHashAndSetActiveAccordion() {
  const hash = route.hash.replace('#', '');
  switch (hash) {
    case 'faq':
      faqIsActive.value = true;
      break;
    case 'support-formular':
      formIsActive.value = true;
      break;
    default:
      break;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tryToDisplayImageInDebugMode = (event: any) => {
  if (!triedToDisplayImageInDebugMode.value) {
    event.target.src = supportImageUrlDebug;
    triedToDisplayImageInDebugMode.value = true;
  }
};

const reloadIframe = () => {
  refreshAsanaIframe.value = refreshAsanaIframe.value === 0 ? 1 : 0;
};

const setModuleTitle = () => {
  AKStore.App.state.currentModuleTitle = t('AKApp.navigation.support');
};

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watchEffect(() => {
  formIsActive.value = computedFormularIsActive.value;
  faqIsActive.value = computedFAQIsActive.value;
  historyIsActive.value = computedHistoryIsActive.value;
  afterConceptIsActive.value = computedAfterConceptIsActive.value;
});

watch(formIsActive, () => {
  if (formIsActive.value) {
    activeDisplayMode.value = AKDisplaySupportMode.FORMULAR;
  }
});

watch(faqIsActive, () => {
  if (faqIsActive.value) {
    activeDisplayMode.value = AKDisplaySupportMode.FAQ;
  }
});

watch(historyIsActive, () => {
  if (historyIsActive.value) {
    activeDisplayMode.value = AKDisplaySupportMode.HISTORY;
  }
});

watch(afterConceptIsActive, () => {
  if (afterConceptIsActive.value) {
    activeDisplayMode.value = AKDisplaySupportMode.AFTERCONCEPT;
  }
});

watch(locale, () => {
  setModuleTitle();
});

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
setModuleTitle();
checkHashAndSetActiveAccordion();
</script>

<script lang="ts">
export enum AKDisplaySupportMode {
  FORMULAR = 'Formular',
  FAQ = 'Faq',
  HISTORY = 'History',
  AFTERCONCEPT = 'AfterConcept',
}
</script>

<style scoped></style>
