<template>
  <AKTooltip :text="t('AKWYSIWYGTextReader.input-tooltip')" :disabled="!enableEditMode">
    <div v-if="props.enableEditMode" :class="calculatedClass" @dblclick="emitChangeToEditorMode()">
      <div ref="viewerContainer" class="h-full"></div>
      <div class="ak_icon" @click="emitChangeToEditorMode()">
        <AKIcon v-if="props.enableEditMode" :framework-icon="PrimeIcons.FILE_EDIT" :scale="1" />
      </div>
    </div>
    <div v-else :class="calculatedClass" @dblclick="emitChangeToEditorMode()">
      <div ref="viewerContainer" class="h-full"></div>
    </div>
  </AKTooltip>
</template>

<script setup lang="ts">
interface PropsInterface {
  text?: string | null;
  placeholder?: string;
  enableEditMode?: boolean;
}
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { AKIcon } from '@ak_tools/components/media'
import { AKTooltip } from '@ak_tools/components/overlay'
import AKTimings from '@ak_tools/ts_modules/core/AKTimings'
import Viewer, { ViewerOptions } from '@toast-ui/editor/dist/toastui-editor-viewer'
import chart from '@toast-ui/editor-plugin-chart'
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell'
import uml from '@toast-ui/editor-plugin-uml'
import { PrimeIcons } from 'primevue/api'
import { computed, onBeforeUnmount, onMounted, onUnmounted, Ref, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  text: null,
  placeholder: 'AKWYSIWYGTextReader.default-placeholder',
  enableEditMode: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const resizeObserver = ref<ResizeObserver>();
const viewerContainer = ref() as Ref<HTMLElement>;
const toastUIViewer = ref<Viewer>();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const calculatedClass = computed(() => {
  return props.enableEditMode ? 'ak-parent-editor p-2' : 'ak-parent-editor-just-read p-2';
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const createViewer = () => {
  const defaultOptions = getDefaultViewerOptions();
  toastUIViewer.value = new Viewer(defaultOptions);
};
const getDefaultViewerOptions = () => {
  const options: ViewerOptions = {
    usageStatistics: false,
    initialValue: props.text ? props.text : t(props.placeholder),
    plugins: [uml, tableMergedCell, chart],
    el: viewerContainer.value,
  };
  return options;
};
const redraw = () => {
  toastUIViewer.value?.destroy();
  createViewer();
};
const debounceRedraw = AKTimings.debounce(() => redraw(), 100);
const resizeDebounceObserver = (entries: ResizeObserverEntry[]) => {
  for (const entry of entries) {
    if (entry.target === viewerContainer.value) {
      debounceRedraw();
    }
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watchEffect(() => {
  const viewerText = props.text ? props.text : t(props.placeholder);
  toastUIViewer.value?.setMarkdown(viewerText);
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onChangeToEditorMode: [];
}>();
const emitChangeToEditorMode = () => {
  emits('onChangeToEditorMode');
};
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  createViewer();
  resizeObserver.value = new ResizeObserver(resizeDebounceObserver);
  resizeObserver.value.observe(viewerContainer.value);
});
onBeforeUnmount(() => {
  resizeObserver.value?.unobserve(viewerContainer.value);
});
onUnmounted(() => {
  resizeObserver.value?.disconnect();
});
</script>

<style lang="sass" scoped>
.ak-parent-editor-just-read
  @apply relative border border-transparent rounded-lg

.ak-parent-editor
  @apply relative border border-transparent rounded-lg

  &:hover
    @apply border-gray-300
    .ak_icon
      @apply block
.ak_icon
  @apply absolute right-2 top-2 hidden h-12 rounded-full bg-white p-4 opacity-20 drop-shadow-md

  &:hover
    @apply cursor-pointer opacity-100
</style>
