import { AKVersion } from '@ak_tools/ts_modules/core/AKVersion';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import {
  AKAppMessage,
  AKAppUIScrollingEvent,
  AKAppUIScrollingStates,
  AKBreakpoints,
  AKOrientation,
  AppState,
  AppWireframes,
} from './types';

const app_var: AppState = {
  appName: 'My App',
  version: new AKVersion({ major: 0, minor: 0, patch: 0 }),
  loadingTimeInMs: 0,
  viewportHeight: 0,
  navigationMenu: [],
  viewportWidth: 0,
  breakpoint: AKBreakpoints.XXL,
  orientation: AKOrientation.landscape,
  currentWireframe: AppWireframes.App,
  currentModuleTitle: '',
  messages: [],
  isMobile: true,
  preLoginURL: '/',
  backendAvailable: true,
  uiStates: {
    out: {
      sidePanelScrollingState: AKAppUIScrollingStates.IDLE,
    },
    in: {
      sidePanelScrollingEvents: [],
    },
  },
};

export const AKAppStore = defineStore('app', () => {
  const state = ref(app_var);

  const displayMessage = (message: AKAppMessage) => {
    state.value.messages.push(message);
  };

  const removeMessage = (messageId: number) => {
    const index = state.value.messages.findIndex((message) => message.messageId === messageId);

    if (index !== -1) {
      state.value.messages.splice(index, 1);
    }
  };

  const addSidePanelScrollingEvent = (sidePanelScrollingEvent: AKAppUIScrollingEvent) => {
    const maxEvents = 200;
    state.value.uiStates.in.sidePanelScrollingEvents.push(sidePanelScrollingEvent);
    if (state.value.uiStates.in.sidePanelScrollingEvents.length > maxEvents) {
      state.value.uiStates.in.sidePanelScrollingEvents.shift();
    }
  };

  const removeSidePanelScrollingEvent = (sidePanelScrollingEvent: AKAppUIScrollingEvent) => {
    const index = state.value.uiStates.in.sidePanelScrollingEvents.indexOf(sidePanelScrollingEvent);
    if (index > -1) {
      state.value.uiStates.in.sidePanelScrollingEvents.splice(index, 1);
    }
  };

  return {
    state,
    displayMessage,
    removeMessage,
    addSidePanelScrollingEvent,
    removeSidePanelScrollingEvent,
  };
});
