export interface AKScrollableTabInterface {
  title: string;
  hash: string;
  element: HTMLElement | null;
  parentElement: HTMLElement | null;
  isInsideContainer: boolean;
  isActive: boolean;
  isMouseOnMe: boolean;
  someoneScrollInTheContainer: () => void;
  activate: (value: boolean) => void;
}

export enum AKScrollEvent {
  STOPPED,
  UP,
  DOWN,
}

export type AKScrollableTabAddSectionFunction = (tab: AKScrollableTabInterface) => void;
export type AKScrollableTabIsOutsideStateChangedFunction = (tab: AKScrollableTabInterface, isOutside: boolean) => void;
