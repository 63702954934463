<template>
  <AKSidePanel
    v-model:default-label-cols="labelCols"
    :show-delete-button="false"
    @close-side-panel="emitCloseSidePanel"
    @maximize-side-panel="emitMaximizeSidePanel"
    @minimize-side-panel="emitMinimizeSidePanel">
    <div v-if="!getWorkDaysLeftInTournament()">
      <span v-text="$t('ReservationDataSidePanel.no-reservations')"></span>
    </div>
    <div
      v-for="timestamp in getWorkDaysLeftInTournament()"
      v-else
      :key="timestamp"
      class="m-auto my-5 w-full max-w-[600px]">
      <ReservationPlanDataTable :timestamp="timestamp" />
      <AKDivider />
    </div>
  </AKSidePanel>
</template>

<script setup lang="ts">
import { AKDataTableRowClickEvent } from '@ak_tools/components/data';
import { AKSidePanel } from '@ak_tools/components/layout';
import AKDivider from '@ak_tools/components/panel/AKDivider.vue';
import { inject, ref } from 'vue';

import { BECommMatch } from '@/backend_communication/definitions/BECommMatches';
import { ReservationDataHandler } from '@/components/reservations/ReservationDataHandler';

import ReservationPlanDataTable from './ReservationPlanDataTable.vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const providedDataHandler = inject('ReservationDataHandler') as ReservationDataHandler;
const labelCols = ref(4);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------

const getWorkDaysLeftInTournament = () => {
  const tournament = providedDataHandler.data.current_tournament;
  if (!tournament) {
    return [];
  }
  const workDaysTimestamps: number[] = [];
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  let currentTimestamp = now.getTime();
  if (currentTimestamp <= tournament.start_date) {
    currentTimestamp = tournament.start_date;
  }
  const oneDayInMs = 24 * 60 * 60 * 1000;

  while (currentTimestamp <= tournament.end_date) {
    const currentDate = new Date(currentTimestamp);

    const dayOfWeek = currentDate.getUTCDay();
    if (dayOfWeek !== 5 && dayOfWeek !== 6) {
      workDaysTimestamps.push(currentTimestamp);
    }
    currentTimestamp += oneDayInMs;
  }

  return workDaysTimestamps;
};

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------

const emits = defineEmits<{
  onReservationRowClick: [AKDataTableRowClickEvent, BECommMatch];
  closeSidePanel: [];
  maximizeSidePanel: [];
  minimizeSidePanel: [];
}>();

const emitCloseSidePanel = () => {
  emits('closeSidePanel');
};

const emitMaximizeSidePanel = () => {
  emits('maximizeSidePanel');
};

const emitMinimizeSidePanel = () => {
  emits('minimizeSidePanel');
};
</script>

<style lang="sass" scoped></style>
