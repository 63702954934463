<template>
  <div class="text-primary button-container flex inline-flex cursor-pointer items-center shadow-none transition-colors">
    <AKIcon
      class="icon-wrapper text-white"
      :framework-icon="PrimeIcons.PLUS"
      :class="{ 'default-hover': !props.customHover }"></AKIcon>
    <div v-if="props.label" class="label text-hover ml-2">
      {{ props.label }}
    </div>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  label?: string;
  customHover?: boolean;
}
import { AKIcon } from '@ak_tools/components/media'
import { PrimeIcons } from 'primevue/api'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  label: undefined,
  customHover: false,
});
</script>

<style lang="sass" scoped>
@import '@ak_tools/assets/ak_styles/variables/default/_ak_colors.sass'

.icon-wrapper
  display: inline-flex
  align-items: center
  justify-content: center
  background-color: $ak-primary
  border-radius: 50%
  width: 45px
  height: 45px

.label
  color: $ak-primary
  &:hover
    color: $ak-primary-1

.button-container
  &:hover
    .icon-wrapper.default-hover
      background-color: $ak-primary-1
</style>
