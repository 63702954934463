<template>
  <div ref="tabSectionRef" class="w-full p-4">
    <div v-if="!hideHeader" class="bg-primary text-theme flex w-full gap-4 rounded-lg p-2 text-center">
      {{ title }}
    </div>
    <div :class="hideHeader ? '' : 'p-4'">
      <slot></slot>
    </div>
    <div class="pt-4"></div>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  title: string;
  hideHeader?: boolean;
}
import { useMouse } from '@vueuse/core'
import { inject, nextTick, onBeforeMount, onMounted, reactive, Ref, ref, watch } from 'vue'
import { AKScrollableTabAddSectionFunction, AKScrollableTabInterface } from './AKScrollableTabTypes'
//  --------------------------------------------------------------------------------------------------------------------
//  props + model
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  title: undefined,
  hideHeader: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const addTabSection = inject('addTabSection') as AKScrollableTabAddSectionFunction;
const tabSectionRef = ref() as Ref<HTMLElement>;
const { x: mouseX, y: mouseY } = useMouse();
const highlightSection = () => {
  if (tabSectionRef.value) {
    tabSectionRef.value.classList.add('animate-highlight-pulse-effect');
    setTimeout(() => {
      tabSectionRef.value.classList.remove('animate-highlight-pulse-effect');
    }, 1500);
  }
};
const updateMouseOnMe = () => {
  if (tabSectionRef.value) {
    const rect = tabSectionRef.value.getBoundingClientRect();
    me.isMouseOnMe =
      mouseX.value >= rect.left &&
      mouseX.value <= rect.right &&
      mouseY.value >= rect.top &&
      mouseY.value <= rect.bottom;
  }
};
const setActiveStateByPositioning = () => {
  if (me.isInsideContainer) {
    updateMouseOnMe();
    if (me.isMouseOnMe) {
      me.isActive = true;
    } else {
      me.isActive = false;
    }
  }
};
const setActiveStateByAction = (value: boolean) => {
  if (!me.isInsideContainer) {
    me.isActive = value;
    if (me.isActive) {
      highlightSection();
    }
  }
};
watch([mouseX, mouseY], () => {
  setActiveStateByPositioning();
});
const someoneScrollInTheContainer = () => {
  setActiveStateByPositioning();
};
function activate(value: boolean) {
  nextTick(() => {
    setActiveStateByAction(value);
  });
}
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
//  --------------------------------------------------------------------------------------------------------------------
//  public expose
//  --------------------------------------------------------------------------------------------------------------------
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onBeforeMount(() => {
  if (addTabSection) {
    me.title = props.title;
    me.hash = `#${me.title.toLowerCase().replace(/ /g, '-')}${Math.floor(Math.random() * 1000000)}}`;
    addTabSection(me);
  }
});
onMounted(() => {
  me.element = tabSectionRef.value;
});
const me: AKScrollableTabInterface = reactive({
  title: props.title,
  element: tabSectionRef.value,
  someoneScrollInTheContainer: someoneScrollInTheContainer,
  activate: activate,
  parentElement: null,
  isInsideContainer: false,
  isActive: false,
  isMouseOnMe: false,
  hash: '',
});
</script>

<style scoped></style>
