<template>
  <div ref="moduleHeaderRef" class="ak-module-header">
    <h2 class="ak-module-header-title" v-text="currentModuleTitle" />
    <div v-if="slotInUse.default" class="flex flex-col items-center gap-4 sm:flex-row">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKStore } from '@ak_tools/start/App';
import { AKAppUIScrollingStates, AKBreakpoints } from '@ak_tools/store/app/types';
import { computed, ref, useSlots, watch } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const slotInUse = useSlots();
const moduleHeaderRef = ref<HTMLElement | null>(null);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------

const currentBreakpoint = computed(() => {
  return AKStore.App.state.breakpoint;
});

const currentModuleTitle = computed(() => {
  return AKStore.App.state.currentModuleTitle;
});

const currentScrollingState = computed(() => {
  return AKStore.App.state.uiStates.out.sidePanelScrollingState;
});

watch(currentScrollingState, (newState) => {
  if (moduleHeaderRef.value && currentBreakpoint.value <= AKBreakpoints.SM) {
    switch (newState) {
      case AKAppUIScrollingStates.UP:
        moduleHeaderRef.value.style.marginTop = '0px';
        break;

      case AKAppUIScrollingStates.DOWN:
        const headerHeight = moduleHeaderRef.value.offsetHeight || 0;
        moduleHeaderRef.value.style.marginTop = `-${headerHeight}px`;
        break;

      default:
    }
  }
});
</script>

<style scoped lang="sass">
@mixin transition-class()
  @apply ease-out transition-all duration-300

.ak-module-header
  @include transition-class
  @apply flex shrink-0 flex-col items-center p-4 border-b border-gray-200 bg-white sm:h-[4rem] sm:flex-row sm:justify-between h-auto


  .ak-module-header-title
    @include transition-class
    @apply text-base text-left sm:text-xl
</style>
