<template>
  <div>
    <AKInputContainer
      :label="props.label"
      :label-cols="props.labelCols"
      :tooltip-text="props.tooltipText"
      :tooltip-placement="props.tooltipPlacement"
      :tooltip-theme="props.tooltipTheme"
      :show-tooltip="props.showTooltip">
      <AKWYSIWYGTextReaderAndEditor
        ref="akWYSIWYGTextReaderAndEditor"
        v-model="inputValue"
        class="w-full"
        :lazy="props.lazy"
        :placeholder="placeholder"
        :enable-markdown-switch="props.enableMarkdownSwitch"
        @on-editor-hide="emitOnEditorHide"
        @on-editor-blur="emitOnEditorBlur"
        @on-input="emitOnInput"
        @on-editor-close="emitOnEditorClose" />
      <template #tooltipContent>
        <slot name="tooltipContent"></slot>
      </template>
    </AKInputContainer>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  placeholder?: string;
  label?: string | false;
  labelCols?: number | 'auto';
  showTooltip?: boolean;
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  lazy?: boolean;
  enableMarkdownSwitch?: boolean;
}
import { AKWYSIWYGTextReaderAndEditor } from '@ak_tools/components/form'
import { AKInputContainer } from '@ak_tools/components/layout'
import { Ref, ref } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const inputValue = defineModel<string | null>({ default: null });
const props = withDefaults(defineProps<PropsInterface>(), {
  placeholder: undefined,
  label: false,
  labelCols: undefined,
  showTooltip: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  lazy: false,
  enableMarkdownSwitch: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akWYSIWYGTextReaderAndEditor = ref() as Ref<InstanceType<typeof AKWYSIWYGTextReaderAndEditor>>;
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const focusEditor = (moveToEnd?: boolean) => {
  akWYSIWYGTextReaderAndEditor.value.focusEditor(moveToEnd);
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onInput: [];
  onEditorHide: [];
  onEditorBlur: [];
  onEditorClose: [];
}>();
const emitOnInput = () => {
  emits('onInput');
};
const emitOnEditorHide = () => {
  emits('onEditorHide');
};
const emitOnEditorBlur = () => {
  emits('onEditorBlur');
};
const emitOnEditorClose = () => {
  emits('onEditorClose');
};
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ focusEditor });
</script>

<style lang="sass" scoped></style>
