<template>
  <div class="flex h-dvh flex-col">
    <AKApplicationHeader
      ref="scrollableAppWireframe"
      class="ak-app-wireframe-scroll-container h-18 w-full sm:h-12"
      @on-menu-state-change="toggleMainMenuState" />

    <div class="relative flex h-full flex-col overflow-hidden sm:flex-row">
      <div class="block sm:w-auto" :class="[{ 'h-full w-screen': mainMenuIsMaximized }]">
        <AKMainMenu ref="akMainMenu" />
      </div>

      <div
        class="flex h-full max-w-full grow flex-col gap-2 overflow-hidden sm:w-full sm:gap-4"
        :class="[{ hidden: mainMenuIsMaximized && currentBreakpoint === AKBreakpoints.MOBILE }]">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKApplicationHeader } from '@ak_tools/components/layout';
import { AKMainMenu, AKMainMenuStates } from '@ak_tools/components/menu';
import { AKStore } from '@ak_tools/start/App';
import { AKBreakpoints } from '@ak_tools/store/app/types';
import { computed, provide, ref } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const mainMenuState = ref<AKMainMenuStates>(AKMainMenuStates.MINIFIED);
const akMainMenu = ref<InstanceType<typeof AKMainMenu> | null>(null);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const mainMenuIsMaximized = computed(() => {
  return mainMenuState.value === AKMainMenuStates.MAXIMIZED;
});

const currentBreakpoint = computed(() => {
  return AKStore.App.state.breakpoint;
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const toggleMainMenuState = () => {
  akMainMenu.value?.toggleMainMenuState();
};

//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('mainMenuState', mainMenuState);
</script>

<style scoped></style>
