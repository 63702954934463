<template>
  <div>
    <AKTextarea
      v-if="!commentIsSet"
      ref="akTextarea"
      v-model="commentValue"
      data-test="ak-comment-send-textarea"
      :placeholder="props.placeholder" />
    <AKEditText v-else ref="akEditText" v-model="commentValue" data-test="ak-comment-send-editor" auto-focus />
    <AKButton
      v-if="commentIsSet"
      class="mt-2 w-full"
      data-test="ak-comment-send-button"
      :label="t('AKCommentSend.button-label')"
      @click="emitOnSendComment" />
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  placeholder?: string;
}
import { AKButton } from '@ak_tools/components/button'
import { AKEditText, AKTextarea } from '@ak_tools/components/form'
import { computed, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  placeholder: 'AKCommentSend.input-placeholder',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const commentValue = ref<string>('');
const akTextarea = ref<InstanceType<typeof AKTextarea> | null>();
const akEditText = ref<InstanceType<typeof AKEditText> | null>();
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const commentIsSet = computed(() => {
  return !!commentValue.value.trim();
});
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(commentValue, (newVal, oldVal) => {
  nextTick(() => {
    if (!newVal) {
      akTextarea.value?.focus();
    } else {
      akEditText.value?.focusEditor(!oldVal);
    }
  });
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onSendComment: [string];
}>();
const emitOnSendComment = () => {
  emits('onSendComment', commentValue.value);
  commentValue.value = '';
};
</script>
