<template>
  <div>
    <div class="bg-primary text-theme flex items-center justify-between rounded-t-lg p-3">
      <span v-text="commentTitle" />
      <AKIcon
        v-if="userIsCommentOwner"
        class="cursor-pointer hover:text-slate-300"
        :scale="0.85"
        :framework-icon="PrimeIcons.TRASH"
        @click="showConfirmDelete = true" />
    </div>
    <div class="rounded-b-lg border bg-white">
      <AKReadAndEditText
        v-if="userIsCommentOwner"
        v-model="editableText"
        lazy
        @update:model-value="handleEditorClose" />
      <AKReadText
        v-else
        :text="props.commentItem.description"
        :placeholder="t('AKWYSIWYGTextReader.default-placeholder')" />
    </div>
  </div>

  <AKConfirmAction
    v-model="showConfirmDelete"
    :confirm-method="ValidConfirmMethods.DEFAULT_CONFIRM"
    :group="`confirmDeleteComment_${commentItem.comment_id}`"
    :check-value="t('AKCommentItem.confirmDeleteValue')"
    :message="t('AKCommentItem.confirmDeleteMessage')"
    @on-confirm="emitDeleteComment" />
</template>

<script setup lang="ts">
type _VTI_TYPE_AKBECommComment = {
  last_modified_datetime: number;
  comment_id: number;
  title: string;
  description: string;
  owner_id: number;
  owner_firstname: string;
  owner_lastname: string;
  created_datetime: number;
}
interface PropsInterface {
  commentItem: _VTI_TYPE_AKBECommComment;
}
import { AKReadAndEditText, AKReadText } from '@ak_tools/components/form'
import { AKIcon } from '@ak_tools/components/media'
import { AKConfirmAction, ValidConfirmMethods } from '@ak_tools/components/overlay'
import { AKStore } from '@ak_tools/start/App'
import { AKDateTime } from '@ak_tools/ts_modules/core/AKDateTime'
import { AKHR } from '@ak_tools/ts_modules/hr/AKHR'
import { PrimeIcons } from 'primevue/api'
import { computed, inject, Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
// --------------------------------------------------------------------------------------------------------------------
// models + props
// --------------------------------------------------------------------------------------------------------------------
const props = defineProps<PropsInterface>();
// --------------------------------------------------------------------------------------------------------------------
// component variables
// --------------------------------------------------------------------------------------------------------------------
const showConfirmDelete = ref(false);
const akDateTime = inject('akDateTime') as Ref<AKDateTime>;
const akHr = new AKHR();
const editableText = ref(props.commentItem.description);
const editedDatetime = ref(props.commentItem.created_datetime || null);
const updateDateTime = ref(props.commentItem.last_modified_datetime || null);
// --------------------------------------------------------------------------------------------------------------------
// computed
// --------------------------------------------------------------------------------------------------------------------
const formattedTime = computed(() => {
  const datetime = editedDatetime.value || props.commentItem.created_datetime;
  return akDateTime.value.getTimeStringFromTimeStamp(datetime, 'HH:mm');
});
const formattedDate = computed(() => {
  const datetime = editedDatetime.value || props.commentItem.created_datetime;
  return akDateTime.value.getStringFromTimeStamp(datetime);
});
const formattedUpdateDate = computed(() => {
  const datetime = updateDateTime.value;
  if (!datetime) {
    return null;
  }
  return akDateTime.value.getStringFromTimeStamp(datetime);
});
const formattedUpdateTime = computed(() => {
  const datetime = updateDateTime.value;
  if (!datetime) {
    return null;
  }
  return akDateTime.value.getTimeStringFromTimeStamp(datetime, 'HH:mm');
});
const commentOwnerFullName = computed(() => {
  const person = {
    firstname: props.commentItem.owner_firstname,
    lastname: props.commentItem.owner_lastname,
  };
  return akHr.getPersonFullName(person);
});
const userIsCommentOwner = computed(() => {
  return AKStore.User.state.userId === props.commentItem.owner_id;
});
const commentTitle = computed(() => {
  let title = '';
  if (userIsCommentOwner.value) {
    title = t('AKCommentItem.comment-title.comment-owner', {
      date: formattedDate.value,
      time: formattedTime.value,
    });
  } else {
    title = t('AKCommentItem.comment-title.not-comment-owner', {
      userName: commentOwnerFullName.value,
      date: formattedDate.value,
      time: formattedTime.value,
    });
  }
  if (updateDateTime.value != editedDatetime.value) {
    const editedTextKey = userIsCommentOwner.value
      ? 'AKCommentItem.comment-title.edited.comment-owner'
      : 'AKCommentItem.comment-title.edited.not-comment-owner';
    title += ` ${t(editedTextKey, {
      userName: commentOwnerFullName.value,
      date: formattedUpdateDate.value,
      time: formattedUpdateTime.value,
    })}`;
  }
  return title;
});
// --------------------------------------------------------------------------------------------------------------------
// emits
// --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  deleteComment: [];
  editorClose: [];
  updateComment: [commentId: number, commentDescription: string, lastModifiedDateTime: number];
}>();
const emitDeleteComment = () => {
  emits('deleteComment');
};
// --------------------------------------------------------------------------------------------------------------------
// methods
// --------------------------------------------------------------------------------------------------------------------
const handleEditorClose = () => {
  if (editableText.value !== props.commentItem.description) {
    const now = new Date();
    updateDateTime.value = akDateTime.value.getTimeStampFromDate(now);
    emits('updateComment', props.commentItem.comment_id, editableText.value, updateDateTime.value);
  }
};
</script>

<style lang="sass" scoped></style>
