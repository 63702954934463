<template>
  <AKModuleHeader>
    <slot name="moduleHeaderActions"></slot>
  </AKModuleHeader>
  <div class="relative m-2 !mt-0 flex h-full gap-4 overflow-hidden">
    <div
      v-show="!maximizedSidePanel"
      class="relative flex grow flex-col overflow-hidden"
      :class="[{ 'hidden lg:flex': visibleSidePanel }]">
      <div v-if="tabInUse || moduleSlotsInUse" class="flex max-h-12" :class="{ 'max-h-none': searchIsVisibleOnMobile }">
        <AKTabs v-if="!searchIsVisibleOnMobile">
          <AKTab
            v-if="slotInUse.board"
            v-model:active="boardIsActive"
            :title="t('AKModuleContainer.tab-board')"></AKTab>
          <AKTab v-if="slotInUse.table" v-model:active="listIsActive" :title="t('AKModuleContainer.tab-list')"></AKTab>
          <AKTab v-if="slotInUse.grid" v-model:active="gridIsActive" :title="t('AKModuleContainer.tab-grid')"></AKTab>
        </AKTabs>
        <div
          v-if="moduleSlotsInUse"
          class="ak-scrollbar-primary-tabs ml-auto flex h-full items-center gap-4 bg-white pl-2.5 pr-4"
          :class="[
            currentBreakpoint < AKBreakpoints.SM
              ? 'rounded-tr-xl'
              : 'ak-rounded-corner-bottom-left ak-rounded-white rounded-t-xl',
            { 'ml-0 w-full rounded-tl-xl py-4 pl-6 pr-6': searchIsVisibleOnMobile },
          ]">
          <div
            v-if="slotInUse.moduleSearch || props.enableModuleSearch"
            class="flex items-center"
            :class="{ 'w-full justify-center': searchIsVisibleOnMobile }">
            <slot name="moduleSearch">
              <AKModuleContainerSearch v-model="searchValue" v-model:input-is-visible="searchInputIsVisible" />
            </slot>
          </div>
          <div v-if="slotInUse.displayOptions && !searchIsVisibleOnMobile" class="flex items-center">
            <slot name="displayOptions"></slot>
          </div>
          <div v-if="slotInUse.moduleActions && !searchIsVisibleOnMobile" class="flex items-center">
            <slot name="moduleActions"></slot>
          </div>
        </div>
      </div>
      <div v-if="currentBreakpoint < AKBreakpoints.SM" class="bg-white px-4">
        <AKDivider class="m-0" />
      </div>
      <div
        class="flex grow flex-col overflow-x-hidden rounded-b-xl bg-white p-4"
        :class="[{ 'rounded-tr-xl': !moduleSlotsInUse }, { 'rounded-tl-xl': !tabInUse }]"
        @scroll="scrollEvent.emit()">
        <div v-if="slotInUse.default">
          <slot></slot>
        </div>
        <div v-if="listIsActive && !slotInUse.default" class="relative flex-col overflow-x-hidden">
          <slot name="table"></slot>
        </div>
        <div v-if="boardIsActive && !slotInUse.default" class="relative grow overflow-hidden">
          <slot name="board"></slot>
        </div>
        <div
          v-if="gridIsActive && !slotInUse.default"
          class="ak-scrollbar-primary relative flex-col overflow-x-hidden"
          @scroll="scrollEvent.emit()">
          <slot name="grid"></slot>
        </div>
      </div>
      <AKModuleAddButton v-if="props.enableAddButton" @click="emitOnItemAdd"></AKModuleAddButton>
    </div>
    <div
      v-show="visibleSidePanel"
      :class="AKStore.App.state.breakpoint > AKBreakpoints.MD ? {} : 'grow overflow-x-auto'">
      <slot name="sidePanel"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  enableModuleSearch?: boolean;
  enableAddButton?: boolean;
}
import AKModuleAddButton from '@ak_tools/components/button/AKModuleAddButton.vue'
import { scrollEvent } from '@ak_tools/components/button/AKModuleAddButtonEventBus'
import { AKDivider, AKTab, AKTabs } from '@ak_tools/components/panel'
import { AKStore } from '@ak_tools/start/App'
import { AKBreakpoints } from '@ak_tools/store/app/types'
import { computed, ref, useSlots, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { AKModuleHeader } from '.'
import AKModuleContainerSearch from './AKModuleContainerSearch.vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const activeDisplayMode = defineModel<AKDisplayMode>('activeDisplayMode', { default: AKDisplayMode.BOARD });
const searchValue = defineModel<string>('searchValue', { default: '' });
const { t } = useI18n();
const props = withDefaults(defineProps<PropsInterface>(), {
  enableModuleSearch: false,
  enableAddButton: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const boardIsActive = ref(false);
const listIsActive = ref(false);
const gridIsActive = ref(false);
const visibleSidePanel = ref(false);
const maximizedSidePanel = ref(false);
const slotInUse = useSlots();
const searchInputIsVisible = ref(false);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const computedBoardIsActive = computed(() => {
  return activeDisplayMode.value === AKDisplayMode.BOARD;
});
const computedListIsActive = computed(() => {
  return activeDisplayMode.value === AKDisplayMode.LIST;
});
const computedGridIsActive = computed(() => {
  return activeDisplayMode.value === AKDisplayMode.GRID;
});
const currentBreakpoint = computed(() => {
  return AKStore.App.state.breakpoint;
});
const tabInUse = computed(() => {
  return !!(slotInUse.board || slotInUse.table || slotInUse.grid);
});
const moduleSlotsInUse = computed(() => {
  return !!(slotInUse.moduleActions || slotInUse.displayOptions || slotInUse.moduleSearch || props.enableModuleSearch);
});
const searchIsVisibleOnMobile = computed(() => {
  return searchInputIsVisible.value && currentBreakpoint.value < AKBreakpoints.SM;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const showSidePanel = () => {
  visibleSidePanel.value = true;
};
const closeSidePanel = () => {
  visibleSidePanel.value = false;
  maximizedSidePanel.value = false;
};
const maximizeSidePanel = () => {
  visibleSidePanel.value = true;
  maximizedSidePanel.value = true;
};
const minimizeSidePanel = () => {
  maximizedSidePanel.value = false;
};
watchEffect(() => {
  boardIsActive.value = computedBoardIsActive.value;
  listIsActive.value = computedListIsActive.value;
  gridIsActive.value = computedGridIsActive.value;
});
watch(boardIsActive, () => {
  if (boardIsActive.value) {
    activeDisplayMode.value = AKDisplayMode.BOARD;
  }
});
watch(listIsActive, () => {
  if (listIsActive.value) {
    activeDisplayMode.value = AKDisplayMode.LIST;
  }
});
watch(gridIsActive, () => {
  if (gridIsActive.value) {
    activeDisplayMode.value = AKDisplayMode.GRID;
  }
});
//  --------------------------------------------------------------------------------------------------------------------
//  emit
//  --------------------------------------------------------------------------------------------------------------------
const emitOnItemAdd = () => {
  emits('addItem');
};
const emits = defineEmits<{
  addItem: [];
}>();
//  --------------------------------------------------------------------------------------------------------------------
//  provided data
//  --------------------------------------------------------------------------------------------------------------------
//  --------------------------------------------------------------------------------------------------------------------
//  expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ showSidePanel, closeSidePanel, maximizeSidePanel, minimizeSidePanel });
</script>

<script lang="ts">
export enum AKDisplayMode {
  BOARD = 'board',
  LIST = 'list',
  PRINT = 'print',
  GRID = 'grid',
}
</script>

<style lang="sass" scoped></style>
