<template>
  <AKKeycloakAccess ref="referenceAKKeycloakAccess" />
  <AKPresetUserData ref="referenceAKPresetUserData" />
</template>

<script setup lang="ts">
import { AKBECommErrorCodes } from '@ak_tools/backend_communication/AKBECommDefault';
import {
  AKBECommApplicationModuleSchema,
  AKBECommLoginData,
  AKBECommLoginRequest,
  AKBECommLoginRequestLoginType,
  AKBECommLoginResponse,
  AKBECommLoginResponseR,
} from '@ak_tools/backend_communication/definitions/users/AKBECommSystemAccess';
import { AKBECommUserGroupSchema } from '@ak_tools/backend_communication/definitions/users/AKBECommUsers';
import { AKBECommSystemAccess } from '@ak_tools/backend_communication/implementations/users/AKBECommSystemAccess';
import {
  AKKeycloakAccess,
  AKKeycloakAccessInstance,
  AKPresetUserData,
  KeycloakStatus,
} from '@ak_tools/components/renderless';
import AKEnv from '@ak_tools/framework_support/AKEnv';
import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider';
import { AKStore } from '@ak_tools/start/App';
import { Application, ApplicationModule, LoginState, Person, UserGroup, UserState } from '@ak_tools/store/users/types';
import { AKVersion } from '@ak_tools/ts_modules/core/AKVersion';
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

class AKUsersActionsCommon {
  state: UserState;

  constructor(userStateFromStore: UserState) {
    this.state = userStateFromStore;
  }
  private getPersonFromResponse(data: AKBECommLoginData): Person {
    let personalNumber = '';
    let employeeOrganizationalUnitAbbr = '';
    let employeeOrganizationalUnitTitle = '';

    if (data.person.employee) {
      personalNumber = data.person.employee.personal_number;
      employeeOrganizationalUnitAbbr = data.person.employee.main_org_unit_abbr;
      employeeOrganizationalUnitTitle = data.person.employee.main_org_unit_title;
    }

    const result: Person = {
      personId: data.person.person_id,
      firstname: data.person.firstname,
      lastname: data.person.lastname,
      gender: data.person.gender,
      title: data.person.title,
      personalNumber,
      employeeOrganizationalUnitAbbr,
      employeeOrganizationalUnitTitle,
      picture: data.person.picture_path,
    };
    return result;
  }
  private getGroupsFromResponse(data: AKBECommLoginData): Array<UserGroup> {
    const result: Array<UserGroup> = [];
    data.user_groups.forEach((userGroup: AKBECommUserGroupSchema) => {
      const newUserGroup: UserGroup = {
        userGroupId: userGroup.user_group_id,
        title: userGroup.title,
        description: userGroup.description,
        abbr: userGroup.abbr,
        private: userGroup.private,
      };
      result.push(newUserGroup);
    });

    return result;
  }

  private getApplicationFromResponse(data: AKBECommLoginData): Application {
    const applicationModules: Array<ApplicationModule> = [];
    const applicationModulesTitles: Array<string> = [];

    if (data.application.modules !== undefined) {
      data.application.modules.forEach((appModule: AKBECommApplicationModuleSchema) => {
        const newAppModule: ApplicationModule = {
          moduleId: appModule.module_id,
          title: appModule.title,
        };
        applicationModules.push(newAppModule);
        applicationModulesTitles.push(newAppModule.title);
      });
    }

    const result: Application = {
      uuid: data.application.uuid,
      title: data.application.title,
      version: new AKVersion({ byText: data.application.version }),
      modules: applicationModules,
      modulesTitles: applicationModulesTitles,
    };

    return result;
  }

  public setLoginDataFromResponse(data: AKBECommLoginResponse) {
    if (data.error_code === AKBECommErrorCodes.no_error) {
      if (data.login_data.logged_in) {
        this.state.loginState = LoginState.LoggedIn;
      } else {
        this.state.loginState = LoginState.LoggedOut;
      }

      this.state.userId = data.login_data.user_id;
      this.state.personalUserGroupId = data.login_data.person.user!.personal_user_group!.user_group_id;

      this.state.person = this.getPersonFromResponse(data.login_data);

      const groups = this.getGroupsFromResponse(data.login_data);
      this.state.userGroups = groups;

      groups.forEach((userGroup: UserGroup) => {
        this.state.userGroups.push(userGroup);
      });

      this.state.application = this.getApplicationFromResponse(data.login_data);
      this.state.eMail = data.login_data.person.user?.email || this.state.eMail;
    } else {
      this.state.loginState = LoginState.LoggedOut;
    }
  }
}

const beComm = new AKBECommSystemAccess(new AKVueProvider());
const userActionsCommon = new AKUsersActionsCommon(AKStore.User.state);
const referenceAKKeycloakAccess = ref<AKKeycloakAccessInstance | null>(null);
const referenceAKPresetUserData = ref<InstanceType<typeof AKPresetUserData> | null>(null);
const route = useRoute();
const router = useRouter();

const login = (loginData: AKBECommLoginRequest) => {
  if (loginData.login_type == AKBECommLoginRequestLoginType.default) {
    AKStore.User.state.loginState = LoginState.PortalLoginPending;
  }
  beComm
    .beCommLogin(loginData)
    .then((response: AKBECommLoginResponseR) => {
      userActionsCommon.setLoginDataFromResponse(response.data);
    })
    .catch(() => {
      if (loginData.login_type == AKBECommLoginRequestLoginType.keycloak) {
        AKStore.User.state.loginState = LoginState.KeycloakAccessFailed;
      } else {
        AKStore.User.state.loginState = LoginState.LoggedOut;
      }
    });
};

const logout = () => {
  beComm.beCommLogout().then((response: AKBECommLoginResponseR) => {
    userActionsCommon.setLoginDataFromResponse(response.data);
  });
};

const tryKeycloakLoginIfNecessary = (appUUID: string, ableToLogout: boolean, redirectUri?: string) => {
  if (AKStore.User.state.loginState === LoginState.LoggedIn) {
    return;
  }
  if (AKStore.User.state.loginState === LoginState.LoggedOut || !ableToLogout) {
    AKStore.User.state.loginState = LoginState.KeycloakLoginPending;
    referenceAKKeycloakAccess.value?.checkLoginState(appUUID, redirectUri).then((keycloakResponse) => {
      if (
        keycloakResponse.keycloakStatus === KeycloakStatus.serverIsAvailable &&
        keycloakResponse.accessToken &&
        keycloakResponse.refreshToken
      ) {
        loginViaOpenConnectIdOnBackend(keycloakResponse.accessToken, keycloakResponse.refreshToken);
      } else if (
        keycloakResponse.keycloakStatus === KeycloakStatus.serverNotAvailable ||
        !keycloakResponse.accessToken
      ) {
        AKStore.User.state.loginState = LoginState.KeycloakAccessFailed;
      }
    });
  }
};

const getLoginState = () => {
  if (AKStore.User.state.loginState === LoginState.LoggedOut) {
    if (route.path.startsWith('/login')) {
      router.push('/');
    }
    beComm
      .beCommGetLoginState()
      .then((response: AKBECommLoginResponseR) => {
        userActionsCommon.setLoginDataFromResponse(response.data);
        if (Boolean(Number(AKEnv.VUE_APP_SKIP_KEYCLOAK_LOGIN))) {
          if (AKStore.User.state.loginState === LoginState.LoggedOut) {
            AKStore.User.state.loginState = LoginState.KeycloakAccessFailed;
          }
        } else {
          tryKeycloakLoginIfNecessary(
            response.data.login_data.application.uuid,
            response.data.login_data.able_to_logout,
            route.path,
          );
        }
      })
      .catch(() => {
        AKStore.User.state.loginState = LoginState.LoggedOut;
      });
  }
};

const loginViaOpenConnectIdOnBackend = (accessToken: string, refreshToken: string) => {
  const loginData: AKBECommLoginRequest = {
    login_type: AKBECommLoginRequestLoginType.keycloak,
    access_token: accessToken,
    refresh_token: refreshToken,
  };
  login(loginData);
};

watchEffect(() => {
  if (AKStore.User.state.loginState === LoginState.LoggedIn) {
    if (referenceAKPresetUserData.value) {
      referenceAKPresetUserData.value.setIt();
    }
  }
});

defineExpose({
  login,
  logout,
  getLoginState,
});
</script>

<script lang="ts"></script>
