<template>
  <div class="ak-flex-col bg-gray-100 pt-3" @scroll="scrollEvent.emit()">
    <div v-if="props.headline" class="ak-text-h4 text-primary my-2 w-full text-center" v-text="props.headline" />
    <div class="flex w-full flex-wrap justify-center gap-6">
      <div v-for="(dataSet, index) in dataRange" :key="index">
        <slot name="gridItem" :data="dataSet"></slot>
      </div>
    </div>
    <AKDivider class="mb-0 mt-2" />
  </div>
  <div bg-white>
    <AKPaginator
      v-if="props.showPagination"
      v-model:first="firstRow"
      v-model:rows="rows"
      v-model:pagination-is-active="paginationIsActive"
      class="mx-auto w-fit"
      show-pagination-toggle
      :total-records="props.data.length">
    </AKPaginator>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  showPagination?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  headline?: string;
}
import { scrollEvent } from '@ak_tools/components/button/AKModuleAddButtonEventBus'
import { AKPaginator } from '@ak_tools/components/data'
import { AKDivider } from '@ak_tools/components/panel'
import { computed, ref } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  showPagination: false,
  headline: '',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const rows = ref(10);
const firstRow = ref(0);
const paginationIsActive = ref(props.showPagination);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const dataRange = computed(() => {
  return paginationIsActive.value ? props.data.slice(firstRow.value, firstRow.value + rows.value) : props.data;
});
</script>

<style lang="sass" scoped></style>
