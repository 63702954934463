<template>
  <AKInputContainer
    :label="props.label"
    :label-cols="props.labelCols"
    :tooltip-text="props.tooltipText"
    :tooltip-placement="props.tooltipPlacement"
    :tooltip-theme="props.tooltipTheme"
    :show-tooltip="props.showTooltip">
    <InputNumber
      v-model="inputValue"
      class="w-full"
      :min="props.minValue"
      :max="props.maxValue"
      :use-grouping="false"
      :show-buttons="props.showButtons"
      :autofocus="props.autofocus"
      :data-test="`inputnumber-${props.dataTest}`"
      :input-class="[textClass, { 'ak-readmode inputnumber': props.enableSwitchReadWriteMode }]"
      :size="props.size"
      :placeholder="t(props.placeholder)"
      :disabled="$props.disabled"
      @blur="setModelValue()"
      @input="onInput"
      @keyup.enter="enterPressed" />
    <template #tooltipContent>
      <slot name="tooltipContent"></slot>
    </template>
  </AKInputContainer>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  dataTest: string;
  placeholder?: string;
  autofocus?: boolean;
  size?: 'small' | 'large';
  enableSwitchReadWriteMode?: boolean;
  fontSize?: 'h1' | 'h2' | 'h3' | 'h4';
  label?: string | false;
  labelCols?: number | 'auto';
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  lazy?: boolean;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  showButtons?: boolean;
  showTooltip?: boolean;
}
import { AKInputContainer } from '@ak_tools/components/layout'
import InputNumber, { InputNumberInputEvent } from 'primevue/inputnumber'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const inputValue = defineModel<number>({ default: 0 });
const props = withDefaults(defineProps<PropsInterface>(), {
  placeholder: 'AKInputNumber.placeholder',
  autofocus: undefined,
  size: undefined,
  enableSwitchReadWriteMode: false,
  fontSize: undefined,
  label: false,
  labelCols: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  inputStyle: undefined,
  lazy: false,
  disabled: false,
  minValue: undefined,
  maxValue: undefined,
  showButtons: false,
  showTooltip: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const textClass = computed(() => {
  if (props.fontSize) {
    return `text-${props.fontSize}`;
  }
  return props.size === 'large' ? 'text-lg' : props.size === 'small' ? 'text-sm' : 'text-base';
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const internalValue = ref<number | null>();
const newInputValue = ref<number>(0);
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const setModelValue = () => {
  inputValue.value = newInputValue.value;
};
const onInput = (input: InputNumberInputEvent) => {
  if (input.value === '' || input.value === undefined) {
    newInputValue.value = 0;
  } else {
    newInputValue.value = typeof input.value === 'string' ? parseInt(input.value) : input.value;
    if (props.maxValue) {
      newInputValue.value = newInputValue.value > props.maxValue ? props.maxValue : newInputValue.value;
    }
  }
  internalValue.value = newInputValue.value;
  if (!props.lazy) {
    setModelValue();
  }
  emitOnInput();
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onInput: [];
  onEnterPressed: [];
}>();
const emitOnInput = () => {
  emits('onInput');
};
const enterPressed = () => {
  emits('onEnterPressed');
};
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
internalValue.value = inputValue.value;
</script>

<style lang="sass" scoped></style>
