<template>
  <DataTable
    v-model:filters="availableFilters"
    :scrollable="props.scrollable"
    scroll-height="flex"
    :global-filter-fields="props.globalFilterFields"
    :data-key="props.dataKey"
    :value="tableData"
    :rows="rowsPerPage"
    :filter-display="displayFilterRow"
    :class="`ak-data-table p-datatable-${props.tableColumnSize}`"
    :paginator="showPaginationIntern && !!paginationPageCountComputed.length"
    :paginator-template="props.paginatorTemplate"
    :rows-per-page-options="paginationPageCountComputed"
    :current-page-report-template="props.currentPageReportTemplate"
    :sort-mode="props.sortMode"
    :sort-field="props.singleSortField"
    :multi-sort-meta="props.multiSortDefinition"
    :selection="selectedRows"
    :selection-mode="props.selectionMode"
    :meta-key-selection="true"
    :loading="props.dataIsLoading"
    :first="firstIndexOfSelectedPage"
    :striped-rows="props.stripedRows"
    row-hover
    removable-sort
    :pt="{
      rowExpansion: '!p-0 !m-0',
    }"
    @row-click="onRowClick"
    @update:selection="onSelectionUpdate"
    @scroll="scrollEvent.emit()">
    <template v-if="slotInUse.header || (props.enableGlobalSearch && globalFilter)" #header>
      <div class="flex justify-between">
        <slot name="header"></slot>
        <div v-if="props.enableGlobalSearch && globalFilter">
          <AKButton
            data-test="test1"
            :icon="PrimeIcons.FILTER_SLASH"
            label="Filter Reset"
            size="small"
            :has-background="false"
            @click="onClearFilterClick()" />
          <span class="p-input-icon-left">
            <AKIcon :framework-icon="PrimeIcons.SEARCH" :scale="0.75" />
            <AKInputText
              v-model="globalFilter.value"
              data-test="ak-data-table-global-filter"
              placeholder="Globale Suche"
              size="small" />
          </span>
        </div>
      </div>
    </template>
    <template v-if="props.showPaginationToggle || slotInUse.footer" #footer>
      <div class="flex flex-col gap-2">
        <div v-if="props.showPaginationToggle && !showPaginationIntern" class="w-full text-center">
          <AKButton
            :label="t('AKDataTable.slots.enable-pagination')"
            variant="secondary"
            size="small"
            :has-background="false"
            @click="showPaginationIntern = true" />
        </div>
        <slot name="footer"></slot>
      </div>
    </template>
    <template #empty>
      <slot name="empty">
        <span v-text="t('AKDataTable.slots.empty')" />
      </slot>
    </template>
    <template #loading>
      <slot name="loading">
        <span v-text="t('AKDataTable.slots.loading')" />
      </slot>
    </template>
    <template v-if="props.showPaginationToggle && showPaginationIntern" #paginatorend>
      <AKButton
        :label="t('AKDataTable.slots.disable-pagination')"
        variant="secondary"
        :has-background="false"
        @click="showPaginationIntern = false" />
    </template>
    <slot></slot>
  </DataTable>
</template>

<script setup lang="ts">
interface PropsInterface {
  dataKey?: string | ((item: unknown) => string);
  dataIsLoading?: boolean;
  globalFilterFields?: string[];
  enableGlobalSearch?: boolean;
  showPagination?: boolean;
  showPaginationToggle?: boolean;
  scrollable?: boolean;
  /**
   * Template of the paginator. It can be customized using the template property using the predefined keys.
   *
   * - FirstPageLink
   * - PrevPageLink
   * - PageLinks
   * - NextPageLink
   * - LastPageLink
   * - RowsPerPageDropdown
   * - JumpToPageDropdown
   * - JumpToPageInput
   * - CurrentPageReport
   * @defaultValue FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown
   */
  paginatorTemplate?: string;
  /**
   * Template of the current page report element. It displays information about the pagination state. Available placeholders are the following;
   *
   * - {currentPage}
   * - {totalPages}
   * - {rows}
   * - {first}
   * - {last}
   * - {totalRecords}
   * @defaultValue 'Seite ({currentPage} von {totalPages})'
   */
  currentPageReportTemplate?: string;
  selectionMode?: 'single' | 'multiple';
  sortMode?: 'single' | 'multiple';
  singleSortField?: string | ((item: unknown) => string);
  singleSortOrder?: 1 | 0 | -1 | null;
  multiSortDefinition?: DataTableSortMeta[];
  tableColumnSize?: 'sm' | 'normal' | 'lg';
  indexToDisplay?: number;
  stripedRows?: boolean;
}
import { AKButton } from '@ak_tools/components/button'
import { scrollEvent } from '@ak_tools/components/button/AKModuleAddButtonEventBus'
import { AKInputText } from '@ak_tools/components/form'
import { AKIcon } from '@ak_tools/components/media'
import { FilterMatchMode, FilterOperator, PrimeIcons } from 'primevue/api'
import { usePrimeVue } from 'primevue/config'
import DataTable, { DataTableFilterMetaData, DataTableSortMeta } from 'primevue/datatable'
import { computed, ref, useSlots, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { AKDataTableFilterMeta, AKDataTableRowClickEvent } from '.'
const { t, locale } = useI18n();
const primevue = usePrimeVue();
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const tableData = defineModel<unknown[]>('tableData', { required: false, default: [] });
const selectedRows = defineModel<unknown[] | unknown>('selectedRows', { required: false, default: [] });
const tableFilters = defineModel<AKDataTableFilterMeta>('tableFilters', { required: false });
const rowsPerPage = defineModel<number>('rowsPerPage', { required: false, default: 10 });
const paginationPageCounts = defineModel<number[]>('paginationPageCounts', {
  required: false,
  default: [10, 20, 50],
});
const props = withDefaults(defineProps<PropsInterface>(), {
  dataKey: undefined,
  indexToDisplay: 0,
  dataIsLoading: false,
  globalFilterFields: undefined,
  enableGlobalSearch: false,
  showPagination: false,
  showPaginationToggle: false,
  currentPageReportTemplate: 'Seite {currentPage} von {totalPages}',
  selectionMode: undefined,
  sortMode: 'multiple',
  singleSortField: undefined,
  singleSortOrder: 1,
  multiSortDefinition: undefined,
  tableColumnSize: 'normal',
  paginatorTemplate:
    'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
  scrollable: undefined,
  stripedRows: true,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const globalFilter = ref<DataTableFilterMetaData>({ value: null, matchMode: AKFilterMatchMode.CONTAINS });
const availableFilters = ref<AKDataTableFilterMeta>();
const firstIndexOfSelectedPage = ref(0);
const slotInUse = useSlots();
const showPaginationIntern = ref(props.showPagination);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const paginationPageCountComputed = computed(() => {
  return paginationPageCounts.value.filter((count) => count < tableData.value.length);
});
const hasGlobalFilter = computed(() => {
  return !!globalFilter.value?.value;
});
const hasTableFilters = computed(() => {
  return Object.keys(tableFilters.value || {}).length > 0;
});
const displayFilterRow = computed(() => {
  return hasGlobalFilter.value || hasTableFilters.value ? 'row' : undefined;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const initGlobalFilter = () => {
  globalFilter.value = { value: null, matchMode: AKFilterMatchMode.CONTAINS };
};
const onRowClick = (row: AKDataTableRowClickEvent) => emitOnRowClick(row);
const onSelectionUpdate = (selection: never[]) => {
  selectedRows.value = selection;
};
const onClearFilterClick = () => {
  initGlobalFilter();
  emitOnClearFilterClick();
};
const getSelectedPageByIndex = () => {
  return Math.ceil((props.indexToDisplay + 1) / rowsPerPage.value);
};
const getFirstItemIndexOfPage = () => {
  const selectedPage = getSelectedPageByIndex();
  return (selectedPage - 1) * rowsPerPage.value;
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watchEffect(() => {
  availableFilters.value = { global: globalFilter.value, ...tableFilters.value };
});
watch(
  () => props.indexToDisplay,
  () => {
    firstIndexOfSelectedPage.value = getFirstItemIndexOfPage();
  },
);
watch(
  locale,
  () => {
    primevue.config.locale!.startsWith = t('AKDataTable.filter-text.starts-with');
    primevue.config.locale!.contains = t('AKDataTable.filter-text.contains');
    primevue.config.locale!.notContains = t('AKDataTable.filter-text.not-contains');
    primevue.config.locale!.endsWith = t('AKDataTable.filter-text.ends-with');
    primevue.config.locale!.equals = t('AKDataTable.filter-text.equals');
    primevue.config.locale!.notEquals = t('AKDataTable.filter-text.not-equals');
    primevue.config.locale!.noFilter = t('AKDataTable.filter-text.no-filter');
  },
  { immediate: true },
);
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick: [AKDataTableRowClickEvent, any];
  onClearFilterClick: [];
}>();
const emitOnRowClick = (clickedRow: AKDataTableRowClickEvent) => {
  emits('onRowClick', clickedRow, clickedRow.data);
};
const emitOnClearFilterClick = () => {
  emits('onClearFilterClick');
};
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
initGlobalFilter();
</script>

<script lang="ts">
export const AKFilterMatchMode = FilterMatchMode;
export const AKFilterOperator = FilterOperator;
</script>

<style lang="sass">
@import "@ak_tools/assets/ak_styles/ak_mixins"

.ak-data-table
  .p-datatable
    .p-datatable-tbody > tr
      @apply cursor-pointer

  .p-datatable-wrapper
    @include ak-scrollbar-primary

  .p-paginator-right-content
    @apply ml-4 #{!important}

.p-dropdown-panel // paginator dropdown
  z-index: 999999 !important // TODO: is a workaround, but pt do not work here

.p-column-filter-overlay // filter dropdown
  z-index: 999999 !important // TODO: is a workaround, but pt do not work here
</style>
