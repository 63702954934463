<template>
  <AKSystemAccess ref="referenceAKSystemAccess" />
  <AKConfirmAction
    v-model="showConfirmAction"
    data-test="confirm-logout"
    group="confirm-logout"
    :message="'Wirklich ausloggen?'"
    :headline="'Ausloggen'"
    @on-confirm="logoutConfirmed">
  </AKConfirmAction>
  <div class="hidden flex-none sm:block">
    <AKButton
      data-test="button-confirm-action-logout"
      size="small"
      :label="t('AKLogoutButton.label')"
      icon-pos="right"
      :icon="PrimeIcons.SIGN_OUT"
      @click="displayConfirmAction" />
  </div>
</template>

<script setup lang="ts">
import { AKButton } from '@ak_tools/components/button';
import { AKConfirmAction } from '@ak_tools/components/overlay';
import { AKSystemAccess } from '@ak_tools/components/renderless';
import { PrimeIcons } from 'primevue/api';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------

const { t } = useI18n();
const showConfirmAction = ref<boolean>(false);
const referenceAKSystemAccess = ref<InstanceType<typeof AKSystemAccess> | null>(null);

const displayConfirmAction = () => {
  showConfirmAction.value = true;
};

const logoutConfirmed = () => {
  referenceAKSystemAccess.value?.logout();
};
</script>

<style scoped></style>
