<template>
  <AKInputContainer
    :label="props.label"
    :label-cols="props.labelCols"
    :tooltip-text="props.tooltipText"
    :tooltip-placement="props.tooltipPlacement"
    :tooltip-theme="props.tooltipTheme">
    <Calendar
      :key="locale"
      v-model="date"
      :auto-z-index="false"
      :disabled="disabled"
      :pt="{
        panel: () => ({
          style: [zIndexStyle],
        }),
      }"
      :class="[{ 'ak-readmode': props.enableSwitchReadWriteMode }, { 'w-full': !props.enableSwitchReadWriteMode }]"
      :show-icon="props.showIcon"
      :date-format="internalDateFormat"
      :view="props.view"
      :placeholder="t(props.placeholder)"
      :touch-u-i="deviceIsMobile"
      :show-button-bar="props.showButtonBar">
      <template #date>
        <slot name="date"></slot>
      </template>
      <template #header>
        <slot name="header"></slot>
      </template>
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </Calendar>
    <template #tooltipContent>
      <slot name="tooltipContent" />
    </template>
  </AKInputContainer>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKLayersKey = keyof typeof akLayers
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  showIcon?: boolean;
  disabled?: boolean;
  dateFormat?: string;
  showButtonBar?: boolean;
  enableSwitchReadWriteMode?: boolean;
  placeholder?: string;
  view?: 'date' | 'month' | 'year';
  label?: string | false;
  labelCols?: number | 'auto';
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  layer?: _VTI_TYPE_AKLayersKey;
}
import { akGetLayer } from '@ak_tools/assets/ak_styles/vendors/tailwind/akLayers'
import { AKInputContainer } from '@ak_tools/components/layout'
import { AKStore } from '@ak_tools/start/App'
import Calendar from 'primevue/calendar'
import { usePrimeVue } from 'primevue/config'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
const { t, locale } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const date = defineModel<null | Date | Date[] | (null | Date)[]>({ default: null });
const props = withDefaults(defineProps<PropsInterface>(), {
  showIcon: true,
  disabled: false,
  dateFormat: undefined,
  showButtonBar: true,
  enableSwitchReadWriteMode: undefined,
  placeholder: 'AKCalendar.input.default-placeholder',
  view: 'date',
  label: 'AKCalendar.default-label',
  labelCols: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  layer: 'moduleLayerFlex2',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const defaultDateFormats = {
  de_DE: 'dd.mm.yy',
  en_EN: 'yy-mm-dd',
};
const internalDateFormat = ref(defaultDateFormats.de_DE);
const primevue = usePrimeVue();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const deviceIsMobile = computed(() => {
  return AKStore.App.state.isMobile;
});
const zIndexStyle = computed(() => {
  return `z-index: ${akGetLayer(props.layer)};`;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const setDateFormat = () => {
  if (props.dateFormat) {
    internalDateFormat.value = props.dateFormat;
  } else {
    internalDateFormat.value = locale.value === 'de_DE' ? defaultDateFormats.de_DE : defaultDateFormats.en_EN;
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(
  locale,
  () => {
    setDateFormat();
    primevue.config.locale!.today = t('AKCalendar.translation.today');
    primevue.config.locale!.clear = t('AKCalendar.translation.clear');
    primevue.config.locale!.monthNames = [
      t('AKCalendar.translation.month-names.january'),
      t('AKCalendar.translation.month-names.february'),
      t('AKCalendar.translation-month-names.march'),
      t('AKCalendar.translation.month-names.april'),
      t('AKCalendar.translation.month-names.may'),
      t('AKCalendar.translation.month-names.june'),
      t('AKCalendar.translation.month-names.july'),
      t('AKCalendar.translation.month-names.august'),
      t('AKCalendar.translation.month-names.september'),
      t('AKCalendar.translation.month-names.october'),
      t('AKCalendar.translation.month-names.november'),
      t('AKCalendar.translation.month-names.december'),
    ];
    primevue.config.locale!.dayNamesMin = [
      t('AKCalendar.translation.day-names-min.sunday'),
      t('AKCalendar.translation.day-names-min.monday'),
      t('AKCalendar.translation.day-names-min.tuesday'),
      t('AKCalendar.translation.day-names-min.wednesday'),
      t('AKCalendar.day-names-min.thursday'),
      t('AKCalendar.translation.day-names-min.friday'),
      t('AKCalendar.translation.day-names-min.saturday'),
    ];
    primevue.config.locale!.monthNamesShort = [
      t('AKCalendar.translation.month-names-short.january'),
      t('AKCalendar.translation.month-names-short.february'),
      t('AKCalendar.translation.month-names-short.march'),
      t('AKCalendar.translation.month-names-short.april'),
      t('AKCalendar.translation.month-names-short.may'),
      t('AKCalendar.translation.month-names-short.june'),
      t('AKCalendar.translation.month-names-short.july'),
      t('AKCalendar.translation.month-names-short.august'),
      t('AKCalendar.translation.month-names-short.september'),
      t('AKCalendar.translation.month-names-short.october'),
      t('AKCalendar.translation.month-names-short.november'),
      t('AKCalendar.translation.month-names-short.december'),
    ];
  },
  { immediate: true },
);
</script>

<style lang="sass" scoped>
.p-calendar
  &.ak-readmode
    @apply shadow-none rounded-xl bg-transparent hover:bg-gray-100

    ::v-deep(.p-inputtext)
      @apply shadow-none cursor-pointer bg-transparent border-transparent min-w-fit

    ::v-deep(.p-button)
      @apply bg-transparent border-transparent text-zinc-500 duration-75 transition-all p-0 shadow-none

      .p-icon
        @apply w-5 h-5

    &:hover
      ::v-deep(.p-button)
        @apply text-gray-700
</style>
