<template>
  <AKInputContainer
    :label="props.label"
    :label-cols="props.labelCols"
    :tooltip-text="props.tooltipText"
    :tooltip-placement="props.tooltipPlacement"
    :tooltip-theme="props.tooltipTheme">
    <InputText
      ref="inputText"
      v-model="internalValue"
      class="w-full"
      :autofocus="props.autofocus"
      :type="props.type"
      :data-test="`inputtext-${props.dataTest}`"
      :class="[props.customClass, textClass, fontClass, { 'ak-readmode inputtext': props.enableSwitchReadWriteMode }]"
      :size="props.size"
      :style="props.inputStyle"
      :placeholder="t(props.placeholder)"
      :autocomplete="props.autocomplete"
      :disabled="$props.disabled"
      :readonly="props.readonly"
      :variant="props.variant"
      :custom-iterable="props.customIterable"
      @blur="setModelValue"
      @input="onInput"
      @keyup.enter="enterPressed" />
    <template #tooltipContent>
      <slot name="tooltipContent"></slot>
    </template>
  </AKInputContainer>
</template>

<script setup lang="ts">
interface _VTI_TYPE_CSSProperties {
    /**
     * The index signature was removed to enable closed typing for style
     * using CSSType. You're able to use type assertion or module augmentation
     * to add properties or an index signature of your own.
     *
     * For examples and more information, visit:
     * https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors
     */
    [v: `--${string}`]: string | number | undefined;
}
type _VTI_TYPE_StyleValue = false | null | undefined | string | _VTI_TYPE_CSSProperties | _VTI_TYPE_Array
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  dataTest: string;
  placeholder?: string;
  autocomplete?: string;
  autofocus?: boolean;
  type?: string;
  size?: 'small' | 'large';
  enableSwitchReadWriteMode?: boolean;
  readonly?: boolean;
  fontStyle?: 'headline';
  fontSize?: 'h1' | 'h2' | 'h3' | 'h4';
  label?: string | false;
  labelCols?: number | 'auto';
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  inputStyle?: _VTI_TYPE_StyleValue;
  lazy?: boolean;
  disabled?: boolean;
  customClass?: string | string[];
  variant?: 'filled' | 'outlined';
  customIterable?: boolean;
}
import { AKInputContainer } from '@ak_tools/components/layout'
import InputText from 'primevue/inputtext'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const inputValue = defineModel<string | null>({ default: '' });
const props = withDefaults(defineProps<PropsInterface>(), {
  placeholder: 'AKInputText.placeholder',
  autocomplete: 'off',
  autofocus: undefined,
  type: 'text',
  size: undefined,
  enableSwitchReadWriteMode: false,
  fontStyle: undefined,
  fontSize: undefined,
  label: false,
  readonly: false,
  labelCols: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  inputStyle: undefined,
  lazy: false,
  disabled: false,
  customClass: undefined,
  variant: undefined,
  customIterable: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const internalValue = ref<string | null>('');
const inputText = ref();
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const textClass = computed(() => {
  if (props.fontSize) {
    return `ak-text-${props.fontSize}`;
  }
  return props.size === 'large' ? 'ak-text-lg' : props.size === 'small' ? 'ak-text-sm' : 'ak-text-md';
});
const fontClass = computed(() => {
  return props.fontStyle ? `ak-${props.fontStyle}` : '';
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const setModelValue = () => {
  inputValue.value = internalValue.value;
};
const onInput = () => {
  if (!props.lazy) {
    setModelValue();
  }
  emitOnInput();
};
const focus = () => {
  inputText.value.$el.focus();
};
const blur = () => {
  inputText.value.$el.blur();
};
//  --------------------------------------------------------------------------------------------------------------------
//  public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ focus, blur });
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onInput: [];
  onEnterPressed: [];
}>();
const emitOnInput = () => {
  emits('onInput');
};
const enterPressed = () => {
  emits('onEnterPressed');
};
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
internalValue.value = inputValue.value;
</script>

<style lang="sass" scoped></style>
