<template>
  <AKViewContainer>
    <div
      class="ak-view-container mx-auto flex w-full max-w-[1200px] flex-col flex-wrap justify-center gap-2 md:min-h-fit md:flex-row md:gap-4 lg:flex-nowrap">
      <div
        v-if="slotInUse.applicationInformation"
        class="order-2 min-h-20 basis-full md:order-3 lg:order-1 lg:basis-1/4">
        <slot name="applicationInformation"></slot>
      </div>

      <AKCard
        class="ak-view-container-welcome-card order-1 grow basis-full md:basis-1/2 lg:order-2"
        :class="{ 'lg:grow-0': slotInUse.systemInformation }">
        <div class="flex flex-col gap-8 text-center">
          <div class="flex flex-col items-center">
            <h3>
              <span v-text="currentWelcomeText" /><span v-text="currentUserFirstname ? ', ' : '!'" />
              <span v-if="currentUserFirstname" class="text-primary" v-text="currentUserFirstname" />
            </h3>

            <div class="bg-backdrop mb-2 mt-4 flex gap-6 rounded-full px-6 py-1 text-lg">
              <span v-text="getFormattedDate" />
              <span v-text="getFormattedTime" />
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <h6 class="text-primary" v-text="t('AKStartViewContainer.quick-navigation')" />
            <AKCenteredNav />
          </div>
        </div>
      </AKCard>

      <AKCard
        v-if="slotInUse.systemInformation"
        class="order-3 basis-full md:order-2 md:basis-1/4 lg:order-3"
        title="System Information">
        <slot name="systemInformation"></slot>
      </AKCard>
    </div>
  </AKViewContainer>
</template>

<script setup lang="ts">
import { AKViewContainer } from '@ak_tools/components/layout';
import { AKCenteredNav } from '@ak_tools/components/menu';
import { AKCard } from '@ak_tools/components/panel';
import { AKStore } from '@ak_tools/start/App';
import { AKDateTime } from '@ak_tools/ts_modules/core/AKDateTime';
import { computed, inject, onBeforeUnmount, Ref, ref, useSlots, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akDateTime = inject('akDateTime') as Ref<AKDateTime>;
const currentDate = ref(new Date());
const slotInUse = useSlots();

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const currentUserFirstname = computed(() => {
  return AKStore.User.state.person.firstname;
});

const getFormattedDate = computed(() => {
  const date = currentDate.value;

  return akDateTime.value.formatDate(date, 'EEEE, d. MMMM');
});

const getFormattedTime = computed(() => {
  const date = currentDate.value;

  return akDateTime.value.formatTime(date, 'HH:mm');
});

const currentWelcomeText = computed(() => {
  const date = currentDate.value;
  const currentHour = akDateTime.value.getHours(date);

  if (currentHour < 12) {
    return t('AKStartViewContainer.greetings-morning');
  } else if (currentHour >= 12 && currentHour < 14) {
    return t('AKStartViewContainer.greetings-lunch');
  } else if (currentHour >= 14 && currentHour < 18) {
    return t('AKStartViewContainer.greetings-afternoon');
  }

  return t('AKStartViewContainer.greetings-evening');
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const updateCurrentDate = () => {
  currentDate.value = new Date();
};

const setModuleTitle = () => {
  AKStore.App.state.currentModuleTitle = AKStore.App.state.appName;
};

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(locale, () => {
  setModuleTitle();
});

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
setModuleTitle();
const updateDateInterval = setInterval(updateCurrentDate, 1000);

onBeforeUnmount(() => {
  clearInterval(updateDateInterval);
});
</script>

<style lang="sass">
.ak-view-container
  &-welcome-card
    .p-card-body
      @apply h-full w-full flex items-center justify-center
</style>
