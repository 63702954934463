<template>
  <div class="flex items-center" :class="{ truncate: !deviceIsMobile }" @click="emitOnMenuItemClick">
    <AKIcon
      v-if="props.icon"
      class="mr-4 flex-none"
      :framework-icon="props.icon"
      :bold="props.currentItemIsActive"
      :scale="1.25" />
    <span
      v-if="!isTruncated"
      ref="textElement"
      class="grow"
      :class="{ 'font-bold': props.currentItemIsActive, truncate: !deviceIsMobile }"
      v-text="t(props.title)" />
    <AKTooltip v-else :class="{ truncate: !deviceIsMobile }" :text="props.title">
      <span
        class="grow"
        :class="{ 'font-bold': props.currentItemIsActive, truncate: !deviceIsMobile }"
        v-text="t(props.title)" />
    </AKTooltip>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  currentItemIsActive: boolean;
  title: string;
  icon?: string;
}
import { AKIcon } from '@ak_tools/components/media'
import { AKTooltip } from '@ak_tools/components/overlay'
import { AKStore } from '@ak_tools/start/App'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const deviceIsMobile = computed(() => {
  return AKStore.App.state.isMobile;
});
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  icon: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component state
//  --------------------------------------------------------------------------------------------------------------------
const isTruncated = ref(false);
const textElement = ref<HTMLElement | null>(null);
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  methods
//  --------------------------------------------------------------------------------------------------------------------
const checkTruncate = () => {
  nextTick(() => {
    setTimeout(() => {
      if (textElement.value) {
        isTruncated.value = textElement.value.scrollWidth > textElement.value.clientWidth;
      }
    }, 200);
  });
};
watch([() => props.title], checkTruncate, { immediate: true });
onMounted(() => {
  checkTruncate();
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onMenuItemClick: [];
}>();
const emitOnMenuItemClick = () => {
  emits('onMenuItemClick');
};
</script>

<style scoped></style>
