<template>
  <div>
    <AKWYSIWYGTextReader
      v-if="!editorIsDisplayed"
      :text="modelValue"
      :placeholder="props.placeholder"
      enable-edit-mode
      @on-change-to-editor-mode="displayEditor" />
    <AKWYSIWYGTextEditor
      v-else
      ref="akWYSIWYGTextEditor"
      v-model="modelValue"
      :placeholder="props.placeholder"
      :lazy="props.lazy"
      auto-focus
      :enable-markdown-switch="props.enableMarkdownSwitch"
      @on-escape="hideEditor"
      @on-input="emitOnInput"
      @on-blur="emitOnEditorBlur"
      @on-close="displayReader" />
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  placeholder?: string;
  lazy?: boolean;
  enableMarkdownSwitch?: boolean;
}
import { AKWYSIWYGTextEditor, AKWYSIWYGTextReader } from '@ak_tools/components/form'
import { nextTick, ref } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const modelValue = defineModel<string | null>({ default: null });
const props = withDefaults(defineProps<PropsInterface>(), {
  placeholder: undefined,
  lazy: false,
  enableMarkdownSwitch: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akWYSIWYGTextEditor = ref<InstanceType<typeof AKWYSIWYGTextEditor> | null>();
const editorIsDisplayed = ref(false);
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const displayEditor = () => {
  editorIsDisplayed.value = true;
};
const displayReader = () => {
  editorIsDisplayed.value = false;
};
const hideEditor = () => {
  editorIsDisplayed.value = false;
  emitOnEditorHide();
};
const focusEditor = (moveToEnd?: boolean) => {
  displayEditor();
  nextTick(() => {
    akWYSIWYGTextEditor.value?.focusEditor(moveToEnd);
  });
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onEditorHide: [];
  onInput: [];
  onEditorBlur: [];
}>();
const emitOnEditorHide = () => {
  emits('onEditorHide');
};
const emitOnInput = () => {
  emits('onInput');
};
const emitOnEditorBlur = () => {
  emits('onEditorBlur');
};
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ focusEditor });
</script>

<style lang="sass" scoped></style>
