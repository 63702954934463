<template>
  <div
    ref="dropZoneRef"
    class="relative flex w-full items-center rounded-lg border"
    :class="{ ' bg-backdrop': isOverDropZone }">
    <div class="absolute h-full w-full" />
    <div class="relative mx-auto flex flex-wrap items-center py-10">
      <AKIcon class="mr-2" :framework-icon="PrimeIcons.CLOUD_UPLOAD" />
      <span v-text="t('AKDropzone.dropzone-drop-file')" />
      <FileUpload
        class="relative ml-2 w-auto"
        mode="basic"
        :choose-label="t('AKDropzone.dropzone-select-files')"
        :custom-upload="true"
        :auto="true"
        @select="onFileSelect" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKIcon } from '@ak_tools/components/media';
import { AKFileListItem } from '@ak_tools/components/misc';
import { useDropZone } from '@vueuse/core';
import { PrimeIcons } from 'primevue/api';
import FileUpload from 'primevue/fileupload';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const dropzoneHover = ref(false);
const dropZoneRef = ref<HTMLDivElement>();
const selectedFiles = ref<File[]>([]);
const uploadFile = ref<AKFileListItem[]>([]);
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const fileToFileListMapping = (file: File): AKFileListItem => {
  return {
    name: file.name,
    type: file.type,
    size: file.size,
    lastModified: file.lastModified,
    fileObject: file,
  };
};

const onFileSelect = (event: { files: File[] }) => {
  selectedFiles.value = event.files;
  upload();
};

const upload = async () => {
  const newFiles = selectedFiles.value.map(fileToFileListMapping);
  uploadFile.value.push(...newFiles);
  emitUploadFiles(newFiles);
};

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  uploadFiles: [files: AKFileListItem[]];
}>();

const emitUploadFiles = (files: AKFileListItem[]) => {
  emits('uploadFiles', files);
};

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
const { isOverDropZone } = useDropZone(dropZoneRef, (files) => {
  dropzoneHover.value = false;
  if (files) {
    const newFiles: AKFileListItem[] = files.map(fileToFileListMapping);
    emitUploadFiles(newFiles);
  }
});
</script>

<style lang="sass" scoped></style>
