<template>
  <AKSidePanelAccordion
    v-if="providedCommentViewData.showCommentAccordion"
    data-test="ak-sidepanel-comment-viewer-accordion"
    :header-title="t('AKSidepanelCommentViewer.accordion-title')">
    <AKCommentView class="h-full" :comments="providedCommentViewData.comments" @delete-comment="emitDeleteComment" />
  </AKSidePanelAccordion>

  <AKCommentView
    v-else
    class="h-full"
    :comments="providedCommentViewData.comments"
    @delete-comment="emitDeleteComment"
    @update-comment="emitUpdateComment" />
</template>

<script setup lang="ts">
import { AKCommentView } from '@ak_tools/components/form';
import { AKSidePanelAccordion, AKSidePanelCommentViewInjectedData } from '@ak_tools/components/layout';
import { inject, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const providedCommentViewData = inject('providedCommentViewData') as Ref<AKSidePanelCommentViewInjectedData>;
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  deleteComment: [itemId: number];
  updateComment: [commentId: number, commentDescription: string, lastModifiedDateTime: number];
}>();

const emitDeleteComment = (itemId: number) => {
  emits('deleteComment', itemId);
};
const emitUpdateComment = (commentId: number, commentDescription: string, last_modified_datetime: number) => {
  emits('updateComment', commentId, commentDescription, last_modified_datetime);
};
</script>

<style lang="sass" scoped></style>
