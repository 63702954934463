<template>
  <div
    ref="sidePanelContainer"
    class="relative flex h-full flex-shrink-0 flex-col bg-transparent shadow-md"
    :style="
      AKStore.App.state.breakpoint > AKBreakpoints.MD
        ? { width: `${sidePanelWidth}px`, minWidth: `${sidePanelWidth}px` }
        : { flexGrow: 1 }
    ">
    <AKSidePanelHeader
      @delete-item="deleteItem"
      @close-side-panel="closeSidePanel"
      @minimize-side-panel="minimizeSidePanel"
      @maximize-side-panel="maximizeSidePanel"
      @duplicate-item="emitDuplicateItem"
      @archive-item="emitArchiveItem"
      @history-item="emitHistoryItem"
      @print-item="emitPrintItem">
      <template #headerLeft>
        <slot name="headerLeft"></slot>
      </template>
      <template #headerAfterButtonMenu>
        <slot name="headerAfterButtonMenu"></slot>
      </template>
    </AKSidePanelHeader>
    <div class="ak-scrollbar-primary h-full w-full grow overflow-x-auto rounded-b-xl bg-white p-4 pt-2">
      <div class="flex h-full flex-col" :style="`min-width: ${props.minWidth}`">
        <div>
          <slot name="bodyHeader"></slot>
        </div>
        <div class="w-full grow overflow-auto">
          <AKScrollableTab
            v-if="showScrollableTabs || showComments || showAttachments"
            @scroll-state-changed="handleScrollEvent">
            <slot></slot>
            <slot name="sidePanelSections"></slot>

            <AKSidePanelSection
              v-if="showAttachments"
              data-test="sidepanel-attachments"
              :title="$t('AKSidePanel.Attachments.Title')">
              <AKSidePanelAttachments
                v-if="props.showAttachments"
                @delete-attachment="emitDeleteAttachment"
                @download-attachment="emitDownloadAttachment"
                @upload-attachments="emitUploadAttachments" />
            </AKSidePanelSection>
            <AKSidePanelSection
              v-if="comments.length > 0"
              data-test="sidepanel-comments"
              :title="$t('AKSidePanel.Comments.Title')">
              <AKSidePanelCommentView @delete-comment="emitDeleteComment" @update-comment="emitUpdateComment" />
            </AKSidePanelSection>
          </AKScrollableTab>
          <div v-else>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>

    <AKCommentSend v-if="props.enableCommentSend" class="mt-4 rounded-xl" @on-send-comment="emitSendComment" />
  </div>
</template>
<script setup lang="ts">
interface PropsInterface {
  showCloseButton?: boolean;
  showMaximizeButton?: boolean;
  showMinimizeButton?: boolean;
  showDeleteButton?: boolean;
  showCopyLocationButton?: boolean;
  showArchiveButton?: boolean;
  showHistoryButton?: boolean;
  showDuplicateButton?: boolean;
  showPrintButton?: boolean;
  showAttachments?: boolean;
  showComments?: boolean;
  showScrollableTabs?: boolean;
  enableCommentSend?: boolean;
  minWidth?: string;
  roundedTopRight?: boolean;
  showCommentAccordion?: boolean;
  showAttachmentAccordion?: boolean;
  itemIsArchived?: boolean;
  sections?: { key: string; value: string }[];
}
import { AKBECommComment } from '@ak_tools/backend_communication/definitions/comments/AKBECommComments'
import { AKBECommAttachment } from '@ak_tools/backend_communication/definitions/files/AKBECommAttachments'
import { AKCommentSend } from '@ak_tools/components/form'
import { AKSidePanelAttachments, AKSidePanelAttachmentsInjectedData, AKSidePanelCommentView, AKSidePanelCommentViewInjectedData, AKSidePanelHeader, AKSidePanelHeaderInjectedData, AKSidePanelSection } from '@ak_tools/components/layout'
import { AKFileListItem } from '@ak_tools/components/misc'
import { AKScrollableTab } from '@ak_tools/components/panel'
import { AKScrollEvent } from '@ak_tools/components/panel/scrollable_tab/AKScrollableTabTypes'
import { AKStore } from '@ak_tools/start/App'
import { AKAppUIScrollingEvent, AKAppUIScrollingEventType, AKBreakpoints, AKBreakpointValues } from '@ak_tools/store/app/types'
import { computed, onBeforeUnmount, onMounted, onUnmounted, provide, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
//  --------------------------------------------------------------------------------------------------------------------
//  props + model
//  --------------------------------------------------------------------------------------------------------------------
const defaultLabelCols = defineModel<number>('defaultLabelCols', { default: 3 });
const sidePanelBreakpoint = defineModel<AKSidePanelBreakpoint>('sidePanelBreakpoint', {
  default: AKSidePanelBreakpoint.MD,
});
const attachments = defineModel<AKBECommAttachment[]>('attachments', { default: [] });
const comments = defineModel<AKBECommComment[]>('comments', { default: [] });
const props = withDefaults(defineProps<PropsInterface>(), {
  showCloseButton: true,
  showMaximizeButton: true,
  showMinimizeButton: true,
  showDeleteButton: true,
  showCopyLocationButton: false,
  showArchiveButton: false,
  showHistoryButton: false,
  showDuplicateButton: false,
  showPrintButton: false,
  showAttachments: false,
  showComments: false,
  showScrollableTabs: false,
  enableCommentSend: false,
  minWidth: '300px',
  roundedTopRight: true,
  showCommentAccordion: false,
  showAttachmentAccordion: false,
  itemIsArchived: false,
  sections: () => [],
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const aKBreakpointLGWidth = AKBreakpointValues.LG;
const menuBarWidth = 280;
const spaceLeftAndRight = 20;
const sidePanelWidth = aKBreakpointLGWidth - menuBarWidth - spaceLeftAndRight;
const sidePanelContainer = ref<HTMLElement>();
const resizeObserver = ref<ResizeObserver>();
const providedHeaderData = computed(() => {
  const data: AKSidePanelHeaderInjectedData = {
    showCloseButton: props.showCloseButton,
    showMaximizeButton: props.showMaximizeButton,
    showMinimizeButton: props.showMinimizeButton,
    showDeleteButton: props.showDeleteButton,
    showCopyLocationButton: props.showCopyLocationButton,
    showArchiveButton: props.showArchiveButton,
    showHistoryButton: props.showHistoryButton,
    showPrintButton: props.showPrintButton,
    showDuplicateButton: props.showDuplicateButton,
    itemIsArchived: props.itemIsArchived,
  };
  return data;
});
const providedAttachmentsData = computed(() => {
  const data: AKSidePanelAttachmentsInjectedData = {
    attachments: attachments.value,
    showAttachmentAccordion: props.showAttachmentAccordion,
  };
  return data;
});
const providedCommentViewData = computed(() => {
  const data: AKSidePanelCommentViewInjectedData = {
    comments: comments.value,
    showCommentAccordion: props.showCommentAccordion,
  };
  return data;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const onResize = () => {
  const width = Number(sidePanelContainer.value?.clientWidth);
  if (width < 700) {
    sidePanelBreakpoint.value = AKSidePanelBreakpoint.SM;
  } else if (width >= 700 && width < 1000) {
    sidePanelBreakpoint.value = AKSidePanelBreakpoint.MD;
  } else {
    sidePanelBreakpoint.value = AKSidePanelBreakpoint.LG;
  }
};
const closeSidePanel = () => {
  emitCloseSidePanel();
};
const minimizeSidePanel = () => {
  emitMinimizeSidePanel();
};
const maximizeSidePanel = () => {
  emitMaximizeSidePanel();
};
const deleteItem = () => {
  emitDeleteItem();
};
const handleScrollEvent = (event: AKScrollEvent) => {
  let targetEvent: AKAppUIScrollingEventType = AKAppUIScrollingEventType.STOP;
  if (event === AKScrollEvent.DOWN) {
    targetEvent = AKAppUIScrollingEventType.DOWN;
  } else if (event === AKScrollEvent.UP) {
    targetEvent = AKAppUIScrollingEventType.UP;
  }
  const sidePanelScrollingEvent: AKAppUIScrollingEvent = {
    event: targetEvent,
  };
  AKStore.App.addSidePanelScrollingEvent(sidePanelScrollingEvent);
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watchEffect(() => {
  defaultLabelCols.value = sidePanelBreakpoint.value === AKSidePanelBreakpoint.LG ? 1 : 3;
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  closeSidePanel: [];
  minimizeSidePanel: [];
  maximizeSidePanel: [];
  deleteItem: [];
  uploadAttachments: [files: AKFileListItem[]];
  deleteAttachment: [item: AKFileListItem];
  downloadAttachment: [item: AKFileListItem];
  updateComment: [commentId: number, commentDescription: string, lastModifiedDateTime: number];
  sendComment: [comment: string];
  deleteComment: [itemId: number];
  archiveItem: [];
  historyItem: [];
  duplicateItem: [];
  printItem: [];
}>();
const emitCloseSidePanel = () => {
  emits('closeSidePanel');
};
const emitMinimizeSidePanel = () => {
  emits('minimizeSidePanel');
};
const emitMaximizeSidePanel = () => {
  emits('maximizeSidePanel');
};
const emitDeleteItem = () => {
  emits('deleteItem');
};
const emitUploadAttachments = (files: AKFileListItem[]) => {
  emits('uploadAttachments', files);
};
const emitDownloadAttachment = (item: AKFileListItem) => {
  emits('downloadAttachment', item);
};
const emitDeleteAttachment = (item: AKFileListItem) => {
  emits('deleteAttachment', item);
};
const emitArchiveItem = () => {
  emits('archiveItem');
};
const emitHistoryItem = () => {
  emits('historyItem');
};
const emitDuplicateItem = () => {
  emits('duplicateItem');
};
const emitPrintItem = () => {
  emits('printItem');
};
const emitSendComment = (comment: string) => {
  emits('sendComment', comment);
};
const emitUpdateComment = (commentId: number, commentDescription: string, last_modified_datetime: number) => {
  emits('updateComment', commentId, commentDescription, last_modified_datetime);
};
const emitDeleteComment = (itemId: number) => {
  emits('deleteComment', itemId);
};
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('providedHeaderData', providedHeaderData);
provide('providedAttachmentsData', providedAttachmentsData);
provide('providedCommentViewData', providedCommentViewData);
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  resizeObserver.value = new ResizeObserver(onResize);
  resizeObserver.value.observe(sidePanelContainer.value as HTMLElement);
});
onBeforeUnmount(() => {
  resizeObserver.value?.unobserve(sidePanelContainer.value as HTMLElement);
});
onUnmounted(() => {
  resizeObserver.value?.disconnect();
});
</script>

<script lang="ts">
export enum AKSidePanelBreakpoint {
  SM,
  MD,
  LG,
}
</script>

<style lang="sass" scoped></style>
