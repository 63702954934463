<template>
  <AKInputContainer
    :label="props.label"
    :label-cols="props.labelCols"
    :tooltip-text="props.tooltipText"
    :tooltip-placement="props.tooltipPlacement"
    :tooltip-theme="props.tooltipTheme">
    <Password
      v-model="inputValue"
      class="w-full"
      type="password"
      :pt="{
        input: { root: { class: 'w-full' } },
      }"
      :feedback="props.feedback"
      :data-test="`input-password-${props.dataTest}`"
      toggle-mask
      :style="props.inputStyle"
      :placeholder="t(props.placeholder)"
      autocomplete="current-password"
      @keyup.enter="enterPressed">
      <template #header>
        <slot name="feedbackHeader"></slot>
      </template>
      <template #footer>
        <slot name="feedbackFooter"></slot>
      </template>
    </Password>
    <template #tooltipContent>
      <slot name="tooltipContent"></slot>
    </template>
  </AKInputContainer>
</template>

<script setup lang="ts">
interface _VTI_TYPE_CSSProperties {
    /**
     * The index signature was removed to enable closed typing for style
     * using CSSType. You're able to use type assertion or module augmentation
     * to add properties or an index signature of your own.
     *
     * For examples and more information, visit:
     * https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors
     */
    [v: `--${string}`]: string | number | undefined;
}
type _VTI_TYPE_StyleValue = false | null | undefined | string | _VTI_TYPE_CSSProperties | _VTI_TYPE_Array
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  dataTest: string;
  placeholder?: string;
  feedback?: boolean;
  label?: string | false;
  labelCols?: number | 'auto';
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  inputStyle?: _VTI_TYPE_StyleValue;
}
import { AKInputContainer } from '@ak_tools/components/layout'
import Password from 'primevue/password'
import { useI18n } from 'vue-i18n'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const inputValue = defineModel<string>({ default: '' });
const { t } = useI18n();
const props = withDefaults(defineProps<PropsInterface>(), {
  placeholder: 'AKInputPassword.default-placeholder',
  feedback: false,
  label: 'AKInputPassword.default-label',
  labelCols: undefined,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  inputStyle: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onEnterPressed: [];
}>();
const enterPressed = () => {
  emits('onEnterPressed');
};
</script>

<style lang="sass" scoped></style>
