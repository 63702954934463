<template>
  <Paginator
    v-if="paginationIsActive"
    v-model:first="firstRow"
    v-model:rows="rows"
    :total-records="props.totalRecords"
    :rows-per-page-options="paginationPageCountComputed"
    :page-link-size="props.pageLinkSize"
    :always-show="props.alwaysVisible"
    :current-page-report-template="props.currentPageReportTemplate"
    :template="props.paginatorTemplate"
    @page="emitOnPageChange">
    <template v-if="slotInUse.start" #start="{ state }">
      <slot name="start" :state="state"></slot>
    </template>
    <template v-if="slotInUse.end || props.showPaginationToggle" #end="{ state }">
      <slot name="end" :state="state"></slot>
      <AKButton
        v-if="props.showPaginationToggle"
        :label="t('AKPaginator.show-all-data')"
        variant="secondary"
        :has-background="false"
        @click="paginationIsActive = false" />
    </template>
  </Paginator>

  <div v-else class="w-full text-center">
    <AKButton
      :label="t('AKPaginator.enable-pagination')"
      variant="secondary"
      :has-background="false"
      @click="paginationIsActive = true" />
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  totalRecords?: number;
  displayCount?: number;
  paginationPageCounts?: number[];
  pageLinkSize?: number;
  alwaysVisible?: boolean;
  /**
   * Template of the paginator. It can be customized using the template property using the predefined keys.
   *
   * - FirstPageLink
   * - PrevPageLink
   * - PageLinks
   * - NextPageLink
   * - LastPageLink
   * - RowsPerPageDropdown
   * - JumpToPageDropdown
   * - JumpToPageInput
   * - CurrentPageReport
   * @defaultValue FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown
   */
  paginatorTemplate?: string;
  /**
   * Template of the current page report element. It displays information about the pagination state. Available placeholders are the following;
   *
   * - {currentPage}
   * - {totalPages}
   * - {rows}
   * - {first}
   * - {last}
   * - {totalRecords}
   * @defaultValue 'Seite ({currentPage} von {totalPages})'
   */
  currentPageReportTemplate?: string;
  showPaginationToggle?: boolean;
}
import { AKButton } from '@ak_tools/components/button'
import Paginator, { PageState } from 'primevue/paginator'
import { computed, useSlots } from 'vue'
import { useI18n } from 'vue-i18n'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const rows = defineModel<number>('rows', { default: 10 });
const firstRow = defineModel<number>('first', { default: 0 });
const paginationIsActive = defineModel<boolean>('paginationIsActive', { default: true });
const { t } = useI18n();
const props = withDefaults(defineProps<PropsInterface>(), {
  totalRecords: 0,
  displayCount: 10,
  paginationPageCounts: () => [5, 10, 20, 30, 40, 50],
  pageLinkSize: 5,
  alwaysVisible: true,
  paginatorTemplate:
    'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
  currentPageReportTemplate: 'Seite {currentPage} von {totalPages}',
  showPaginationToggle: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const slotInUse = useSlots();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const paginationPageCountComputed = computed(() => {
  return props.paginationPageCounts.filter((count) => count < props.totalRecords);
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onPageChange: [PageState];
}>();
const emitOnPageChange = (pageState: PageState) => {
  emits('onPageChange', pageState);
};
</script>

<style lang="sass" scoped></style>
