<template>
  <div class="ak-toast p-toast">
    <div class="p-toast-message" :class="messageVariantClass">
      <div class="p-toast-message-content flex w-full flex-col gap-2 border-l-0">
        <div class="flex w-full items-start justify-between">
          <div class="flex gap-2">
            <slot name="headlineIcon">
              <AKIcon class="p-toast-message-icon pt-1.5" :framework-icon="messageVariantIcon" />
            </slot>
            <slot name="headline">
              <span class="p-toast-summary ak-headline align-top text-lg" v-text="convertedMessage.headline" />
            </slot>
          </div>

          <div class="flex gap-2">
            <AKIcon
              v-if="convertedMessage.detail"
              class="transform cursor-pointer transition-transform duration-200 hover:brightness-50"
              :framework-icon="PrimeIcons.CHEVRON_UP"
              :class="{ 'rotate-180': displayMessageDetail }"
              @click="toggleDisplayMessage" />

            <AKIcon
              v-if="displayMessageDetail && allowCopyMessage"
              class="cursor-pointer hover:brightness-50"
              :framework-icon="PrimeIcons.COPY"
              @click="emitCopyErrorMessage" />

            <slot name="closeIcon">
              <AKIcon
                class="cursor-pointer pt-0.5 hover:brightness-50"
                :framework-icon="PrimeIcons.TIMES"
                :scale="0.8"
                @click="emitOnClose" />
            </slot>
          </div>
        </div>

        <div v-if="displayMessageDetail" class="flex w-full flex-col gap-2">
          <AKDivider class="my-2" />

          <div class="ak-scrollbar-primary max-h-[60vh] overflow-y-auto">
            <slot name="detail">
              <ul v-if="Array.isArray(convertedMessage.detail)" class="list-disc">
                <li v-for="(string, index) in convertedMessage.detail" :key="index" class="ml-8" v-text="string" />
              </ul>
              <p v-else class="ak-scrollbar-primary break-words" v-text="convertedMessage.detail" />
            </slot>
          </div>
        </div>
        <div v-if="messageIsBeComm" class="flex w-full flex-col gap-2">
          <AKDivider class="my-2" />

          <span
            class="text-primary cursor-pointer hover:underline hover:brightness-75"
            @click="sendError"
            v-text="t('AKToast.send-error')" />
        </div>
        <slot name="footer">
          <AKInterval
            v-if="showInterval"
            ref="akIntervalRef"
            v-model:remaining-time="remainingTime"
            :timer="displayTime" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKAppMessageBeCommError = AKAppMessageBase & {
  messageType: AKMessageType.BE_COMM_ERROR;
  errorData: AKBECommErrorResponseSchema;
}
type _VTI_TYPE_AKAppMessageError = AKAppMessageBase & {
  messageType: AKMessageType.ERROR;
}
type _VTI_TYPE_AKAppMessageWarning = AKAppMessageBase & {
  messageType: AKMessageType.WARNING;
}
type _VTI_TYPE_AKAppMessageInfo = AKAppMessageBase & {
  messageType: AKMessageType.INFO;
}
type _VTI_TYPE_AKAppMessageSuccess = AKAppMessageBase & {
  messageType: AKMessageType.SUCCESS;
}
type _VTI_TYPE_AKAppMessage = | _VTI_TYPE_AKAppMessageSuccess
  | _VTI_TYPE_AKAppMessageInfo
  | _VTI_TYPE_AKAppMessageWarning
  | _VTI_TYPE_AKAppMessageError
  | _VTI_TYPE_AKAppMessageBeCommError
interface PropsInterface {
  message: _VTI_TYPE_AKAppMessage;
}
import { AKIcon } from '@ak_tools/components/media'
import { AKToastDisplayTime, AKToastMessageOptions } from '@ak_tools/components/messages'
import { AKInterval } from '@ak_tools/components/misc'
import { AKDivider } from '@ak_tools/components/panel'
import { AKMessageType } from '@ak_tools/store/app/types'
import { PrimeIcons } from 'primevue/api'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps<PropsInterface>();
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const displayMessageDetail = ref(false);
const showInterval = ref(true);
const displayTime = ref(0);
const remainingTime = ref(0);
const akIntervalRef = ref<InstanceType<typeof AKInterval>>();
const router = useRouter();
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const messageVariantClass = computed(() => {
  switch (props.message.messageType) {
    case AKMessageType.ERROR:
      return 'p-toast-message-error';
    case AKMessageType.SUCCESS:
      return 'p-toast-message-success';
    case AKMessageType.WARNING:
      return 'p-toast-message-warn';
    case AKMessageType.INFO:
      return 'p-toast-message-info';
    default:
      return 'p-toast-message-error';
  }
});
const messageVariantIcon = computed(() => {
  switch (props.message.messageType) {
    case AKMessageType.ERROR:
    case AKMessageType.BE_COMM_ERROR:
    case AKMessageType.WARNING:
      return PrimeIcons.EXCLAMATION_TRIANGLE;
    case AKMessageType.SUCCESS:
      return PrimeIcons.CHECK_CIRCLE;
    default:
      return PrimeIcons.INFO_CIRCLE;
  }
});
const allowCopyMessage = computed(() => {
  return messageIsBeComm.value;
});
const convertedMessage = computed(() => {
  const { messageType, messageSummary, messageTranslatableParts } = props.message;
  const detail =
    messageType === AKMessageType.BE_COMM_ERROR
      ? `Error: ${props.message.errorData.error_code}\n Error ID: ${props.message.errorData.error_id}\nMessage: ${props.message.errorData.error_message}\nTimestamp: ${props.message.errorData.server_timestamp}`
      : messageTranslatableParts;
  const toastData: AKToastMessageOptions = {
    headline: messageType === AKMessageType.BE_COMM_ERROR ? props.message.errorData.error_text : messageSummary,
    detail: detail,
  };
  return toastData;
});
const messageIsBeComm = computed(() => {
  return props.message.messageType === AKMessageType.BE_COMM_ERROR;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const sendError = () => {
  stopInterval();
  router.push({ name: 'support' });
};
const toggleDisplayMessage = () => {
  displayMessageDetail.value = !displayMessageDetail.value;
  stopInterval();
};
const stopInterval = () => {
  akIntervalRef.value?.endInterval();
  showInterval.value = false;
};
const showToast = () => {
  displayTime.value = props.message.displayTimeInMS ?? getDisplayTime(props.message);
  emitOnShow();
};
const getDisplayTime = (message: AKAppMessage) => {
  return message.messageType !== AKMessageType.ERROR && message.messageType !== AKMessageType.BE_COMM_ERROR
    ? AKToastDisplayTime.DEFAULT
    : AKToastDisplayTime.ERROR;
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(remainingTime, (newTime) => {
  nextTick(() => {
    if (!newTime) {
      emitOnClose();
    }
  });
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  copyErrorMessage: [];
  onMount: [];
  onShow: [];
  onClose: [];
}>();
const emitCopyErrorMessage = () => {
  emits('copyErrorMessage');
};
const emitOnMount = () => {
  emits('onMount');
};
const emitOnShow = () => {
  emits('onShow');
};
const emitOnClose = () => {
  emits('onClose');
};
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  emitOnMount();
  showToast();
});
</script>

<style lang="sass" scoped>
@import '@/assets/ak_styles/theme'

.ak-toast
  @apply z-error-messages-layer opacity-100

  .p-toast-message
    color: $ak-text-color
    @apply bg-white shadow-2xl
</style>
