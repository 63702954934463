<template>
  <AKIcon
    class="cursor-pointer hover:text-black"
    :class="[menuIsVisible ? 'text-black' : 'text-gray-500']"
    :framework-icon="PrimeIcons.ELLIPSIS_H"
    @click="toggleMenu" />

  <AKTieredMenu ref="akTieredMenu" v-model:menu-is-visible="menuIsVisible" :items="items" />
</template>

<script setup lang="ts">
interface PropsInterface {
  label?: string;
}
import { AKIcon } from '@ak_tools/components/media'
import { AKMenuItem, AKTieredMenu } from '@ak_tools/components/menu'
import { PrimeIcons } from 'primevue/api'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  models+props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  label: 'AKDefaultModuleAddAction.create-element',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akTieredMenu = ref<InstanceType<typeof AKTieredMenu> | null>(null);
const menuIsVisible = ref<boolean>(false);
const items: AKMenuItem[] = [
  {
    command: () => emitOnItemAdd(),
    label: t(props.label),
    icon: PrimeIcons.PLUS,
    shortcut: 'ALT + CTRL + N',
  },
];
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const toggleMenu = (event: Event) => {
  akTieredMenu.value?.toggleMenu(event);
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emitOnItemAdd = () => {
  emits('addItem');
};
const emits = defineEmits<{
  addItem: [];
}>();
</script>

<style lang="sass" scoped></style>
