import { AKVersion } from '@ak_tools/ts_modules/core/AKVersion';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { LoginState, UserState } from './types';

export const user_state: UserState = {
  userId: 0,
  userName: 'no@abus-kransysteme.de',
  personalUserGroupId: 0,
  userGroups: [
    {
      userGroupId: 0,
      title: '',
      description: '',
      abbr: '',
      private: false,
    },
  ],
  userGroupsTitles: [],
  application: {
    uuid: '',
    title: '',
    version: new AKVersion({ major: 0, minor: 0, patch: 0 }),
    modules: [],
    modulesTitles: [],
  },
  person: {
    personId: 0,
    firstname: '',
    lastname: '',
    gender: '',
    title: '',
    personalNumber: '',
    employeeOrganizationalUnitAbbr: '',
    employeeOrganizationalUnitTitle: '',
    picture: '',
  },
  eMail: 'no@abus-kransysteme.de',
  loginState: LoginState.LoggedOut,
};

export const AKUserStore = defineStore('users', () => {
  const state = ref(user_state);
  const getUserFullName = computed(() => `${state.value.person.firstname} ${state.value.person.lastname}`);
  const getUserGroups = computed(() => state.value.userGroups);

  const userIsAdmin = computed(() => {
    return getUserGroups.value.some((group) => group.abbr === 'aadmin');
  });

  return { state, getUserFullName, getUserGroups, userIsAdmin };
});
