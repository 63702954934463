<template>
  <div class="flex flex-col gap-2">
    <AKCommentItem
      v-for="comment in props.comments"
      :key="comment.comment_id"
      :comment-item="comment"
      @delete-comment="emitDeleteComment(comment.comment_id)"
      @update-comment="emitUpdateComment" />
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  comments?: AKBECommComment[];
}
import { AKBECommComment } from '@ak_tools/backend_communication/definitions/comments/AKBECommComments'
import { AKCommentItem } from '@ak_tools/components/form'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  comments: () => [],
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  deleteComment: [itemId: number];
  updateComment: [commentId: number, commentDescription: string, lastModifiedDateTime: number];
}>();
const emitDeleteComment = (commentId: number) => {
  emits('deleteComment', commentId);
};
const emitUpdateComment = (commentId: number, commentDescription: string, lastModifiedDateTime: number) => {
  emits('updateComment', commentId, commentDescription, lastModifiedDateTime);
};
</script>

<style lang="sass" scoped></style>
