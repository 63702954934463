<template>
  <AKScrollableTabSection :hide-header="props.hideHeader" :title="props.title">
    <template #header>
      <AKTooltip
        v-if="props.showTooltip"
        :placement="props.tooltipPlacement"
        :tooltip-distance="35"
        :text="props.tooltipText"
        :theme="props.tooltipTheme">
        <span v-text="props.title" />
        <template #content>
          <slot name="tooltipContent" />
        </template>
      </AKTooltip>
      <span v-else v-text="props.title" />
    </template>
    <slot></slot>
    <AKMenu v-if="props.buttonMenuItems" ref="akMenu" :items="props.buttonMenuItems" />
    <div class="AddButtonContainer flex">
      <AKAddButton v-if="showAddButton" :data-test="dataTest" :label="'asd'" @click="openMenuOrAddItem" />
    </div>
  </AKScrollableTabSection>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKTooltipValidThemes = 'ak-white'
type _VTI_TYPE_AKTooltipValidPlacements = | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface PropsInterface {
  dataTest?: string;
  title: string;
  hideHeader?: boolean;
  buttonMenuItems?: AKMenuItem[];
  showAddButton?: boolean;
  active?: boolean;
  tooltipText?: string;
  tooltipPlacement?: _VTI_TYPE_AKTooltipValidPlacements;
  tooltipTheme?: _VTI_TYPE_AKTooltipValidThemes;
  showTooltip?: boolean;
}
import AKAddButton from '@ak_tools/components/button/AKAddButton.vue'
import { AKMenu, AKMenuItem } from '@ak_tools/components/menu'
import { AKTooltip } from '@ak_tools/components/overlay'
import { AKScrollableTabSection } from '@ak_tools/components/panel'
import { ref } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  title: undefined,
  hideHeader: false,
  buttonMenuItems: () => [],
  active: false,
  showAddButton: false,
  tooltipText: undefined,
  tooltipPlacement: undefined,
  tooltipTheme: undefined,
  showTooltip: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const akMenu = ref<InstanceType<typeof AKMenu> | null>(null);
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const openMenuOrAddItem = (e: Event) => {
  if (props.buttonMenuItems.length != 0) {
    akMenu.value?.showMenu(e);
  } else {
    emitOnAdd();
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onAdd: [];
}>();
const emitOnAdd = () => {
  emits('onAdd');
};
</script>

<style lang="sass" scoped></style>
