<template>
  <Badge :value="props.value" :size="props.size" :severity="props.variant">
    <slot></slot>
  </Badge>
</template>

<script setup lang="ts">
interface PropsInterface {
  value?: string | number;
  size?: 'large' | 'xlarge';
  variant?: 'secondary' | 'info' | 'success' | 'warning' | 'danger' | 'contrast';
}
import Badge from 'primevue/badge'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  value: undefined,
  size: undefined,
  variant: undefined,
});
</script>

<style lang="sass" scoped></style>
