<template>
  <span
    v-if="props.userGroup.private && (props.userGroup.display_name === null || props.userGroup.display_name === '')"
    :tabindex="0"
    v-text="t('AKEmployeeCard.private')" />
  <span v-else :tabindex="0" v-text="props.userGroup.display_name" />
</template>

<script setup lang="ts">
type _VTI_TYPE_AKBECommUserGroupSchema = {
  user_group_id: number;
  title: string;
  abbr: string;
  short_abbr: string;
  description: string;
  source: AKBECommUserGroupSourceSchema | null;
  type: AKBECommUserGroupTypeSchema | null;
  active: boolean;
  child_groups: Array<AKBECommUserGroupChildSchema>;
  maintain_groups: Array<AKBECommUserGroupMaintainSchema>;
  parent_group_ids: Array<number>;
  child_group_ids: Array<number>;
  role: AKBECommRoleSchema | null;
  display_name: string;
  display_name_en: string;
  directory: AKBECommUserGroupDirectorySchema | null;
  private: boolean;
  restrict_user_visibility: boolean;
  parent_groups: Array<AKBECommUserGroupSmall>;
}
interface PropsInterface {
  userGroup: _VTI_TYPE_AKBECommUserGroupSchema;
}
import { useI18n } from 'vue-i18n'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps<PropsInterface>();
const { t } = useI18n();
</script>

<style lang="sass" scoped></style>
