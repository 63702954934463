<template>
  <div class="flex h-full flex-col">
    <div
      v-if="headerIsBottom"
      ref="scrollableContent"
      class="ak-scrollbar-primary flex grow flex-col gap-2 overflow-auto p-2">
      <slot></slot>
    </div>
    <div class="flex flex-shrink-0 grow-0 flex-col">
      <div class="scrollable-tab-header flex pb-2">
        <AKIcon
          class="scrollable-tab-clickable-button flex pr-2"
          :bold="true"
          :framework-icon="PrimeIcons.CHEVRON_LEFT"
          @click="prevTab" />
        <div ref="scrollableTabs" class="scrollable-tab">
          <div
            v-for="section in sections"
            ref="sectionHeaderTitlesRefs"
            :key="section.hash"
            class="badge-container"
            :class="{ active: section.isActive }">
            <AKButton
              class="bold-button"
              rounded
              :has-background="section.isActive ? undefined : false"
              @click="() => activateSection(section)">
              {{ section.title }}
            </AKButton>
          </div>
        </div>
        <AKIcon
          class="scrollable-tab-clickable-button flex pl-2"
          :bold="true"
          :framework-icon="PrimeIcons.CHEVRON_RIGHT"
          @click="nextTab" />
      </div>
    </div>
    <div
      v-if="!headerIsBottom"
      ref="scrollableContent"
      class="ak-scrollbar-primary flex grow flex-col gap-2 overflow-auto p-2">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKButton } from '@ak_tools/components/button';
import { AKScrollableTabAddSectionFunction, AKScrollableTabInterface } from '@ak_tools/components/layout';
import { AKIcon } from '@ak_tools/components/media';
import { useMouseInElement, useScroll } from '@vueuse/core';
import { PrimeIcons } from 'primevue/api';
import { computed, onMounted, provide, reactive, ref, watch } from 'vue';

import { AKScrollEvent } from './AKScrollableTabTypes';
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const sections = ref<Array<AKScrollableTabInterface>>([]);
const scrollableContent = ref<HTMLElement | null>(null);
const scrollableTabs = ref<HTMLElement | null>(null);
const sectionHeaderTitlesRefs = ref<Record<string, HTMLElement | null>>({});
const { isOutside: isOutsideContainer } = useMouseInElement(scrollableContent);
const { isScrolling, directions: scrollDirections } = useScroll(scrollableContent);
let scrollTimer: ReturnType<typeof setInterval> | null = null;
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const headerIsBottom = computed(() => {
  return false;
});
//  --------------------------------------------------------------------------------------------------------------------
//  Children Handling
//  --------------------------------------------------------------------------------------------------------------------
const addTabSection: AKScrollableTabAddSectionFunction = (tab: AKScrollableTabInterface) => {
  const reactiveTab = reactive(tab);
  sections.value.push(reactiveTab);
};
provide('addTabSection', addTabSection);
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const scrollToSection = (section: AKScrollableTabInterface) => {
  if (section.element) {
    section.element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  }
};

const scrollToHeaderTab = (index: number) => {
  const targetElement = sectionHeaderTitlesRefs.value[index];
  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'nearest',
    });
  }
};

const activateSection = (section: AKScrollableTabInterface) => {
  sections.value.forEach((sectionIterate: AKScrollableTabInterface) => {
    if (sectionIterate.hash === section.hash) {
      scrollToSection(sectionIterate);
      sectionIterate.activate(true);
    } else {
      sectionIterate.activate(false);
    }
  });
};

const nextTab = () => {
  const currentIndex = sections.value.findIndex((section) => section.isActive);
  if (currentIndex < sections.value.length - 1) {
    activateSection(sections.value[currentIndex + 1]);
  }
};

const prevTab = () => {
  const currentIndex = sections.value.findIndex((section) => section.isActive);
  if (currentIndex > 0) {
    activateSection(sections.value[currentIndex - 1]);
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  Scrolling Feedback
//  --------------------------------------------------------------------------------------------------------------------
enum ScrollState {
  IDLE,
  UP,
  DOWN,
}

enum ScrollStateMachineEvent {
  ScrollingStartDown,
  ScrollingStartUp,
  ScrollingStop,
  ScrollingChange,
}

const scrollState = ref<ScrollState>(ScrollState.IDLE);

const emit = defineEmits<{
  scrollStateChanged: [event: AKScrollEvent];
}>();

const scrollStateMachine = (event: ScrollStateMachineEvent) => {
  switch (scrollState.value) {
    case ScrollState.IDLE:
      {
        switch (event) {
          case ScrollStateMachineEvent.ScrollingStartDown:
            scrollState.value = ScrollState.DOWN;
            break;
          case ScrollStateMachineEvent.ScrollingStartUp:
            scrollState.value = ScrollState.UP;
            break;
        }
      }
      break;
    case ScrollState.DOWN: {
      switch (event) {
        case ScrollStateMachineEvent.ScrollingStop:
        case ScrollStateMachineEvent.ScrollingChange:
          emit('scrollStateChanged', AKScrollEvent.DOWN);
          if (event === ScrollStateMachineEvent.ScrollingStop) {
            scrollState.value = ScrollState.IDLE;
            emit('scrollStateChanged', AKScrollEvent.STOPPED);
          }
          break;
      }
      break;
    }
    case ScrollState.UP:
      {
        switch (event) {
          case ScrollStateMachineEvent.ScrollingStop:
          case ScrollStateMachineEvent.ScrollingChange:
            emit('scrollStateChanged', AKScrollEvent.UP);
            if (event === ScrollStateMachineEvent.ScrollingStop) {
              scrollState.value = ScrollState.IDLE;
              emit('scrollStateChanged', AKScrollEvent.STOPPED);
            }
            break;
        }
      }
      break;
  }
};

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(scrollDirections, () => {
  if (isOutsideContainer.value) {
    return;
  }

  if (scrollDirections.bottom) {
    scrollStateMachine(ScrollStateMachineEvent.ScrollingStartDown);
  } else if (scrollDirections.top) {
    scrollStateMachine(ScrollStateMachineEvent.ScrollingStartUp);
  }

  if (!scrollDirections.bottom && !scrollDirections.top) {
    scrollStateMachine(ScrollStateMachineEvent.ScrollingStop);
  }
});
watch(isScrolling, (scrolling) => {
  if (!scrolling && isOutsideContainer.value) {
    isOutsideContainer.value = false;
  }

  if (scrolling) {
    if (!scrollTimer) {
      scrollTimer = setInterval(() => {
        if (!isOutsideContainer.value) {
          scrollStateMachine(ScrollStateMachineEvent.ScrollingChange);
        }
        sections.value.forEach((section) => {
          section.someoneScrollInTheContainer();
        });
      }, 100);
    }
  } else {
    if (scrollTimer) {
      clearInterval(scrollTimer);
      scrollTimer = null;
    }
  }
});

watch(isOutsideContainer, () => {
  sections.value.forEach((section: AKScrollableTabInterface) => {
    section.isInsideContainer = !isOutsideContainer.value;
  });
});

onMounted(() => {
  sections.value.forEach((section: AKScrollableTabInterface) => {
    section.parentElement = scrollableContent.value;
    watch(
      () => section.isActive,
      (newValue) => {
        if (newValue) {
          scrollToHeaderTab(sections.value.indexOf(section));
        }
      },
    );
  });
  activateSection(sections.value[0]);
});
</script>

<style lang="sass" scoped>
@import '@ak_tools/assets/ak_styles/variables/default/_ak_colors.sass'

.scrollable-tab-header
  display: flex
  justify-content: space-between
  align-items: center

.bold-button
  font-weight: bold

.scrollable-tab-clickable-button
  cursor: pointer
  color: $ak-primary-1

.scrollable-tab
  display: flex
  overflow-x: auto
  white-space: nowrap
  pointer-events: all

  &::-webkit-scrollbar
    height: 0px

  &::-webkit-scrollbar-thumb
    background-color: $ak-primary-1
    border-radius: 4px
</style>
