import { AKBECommErrorResponseSchema } from '@ak_tools/backend_communication/AKBECommDefault';
import { AKVersionInterface } from '@ak_tools/ts_modules/core/AKVersion';
import { Component } from 'vue';

/**
 * Possible Wireframes of the Application
 */
export enum AppWireframes {
  /** This is the Login Screen */
  LogIn = 0,
  /** This is the normal Application */
  App = 1,
  /** This is the Error Screen */
  Error = 2,
  /** This is the Print Screen */
  Print = 3,
  /** This is the Loading Screen */
  Loading = 4,
}

export enum AKOrientation {
  landscape = 0,
  portrait = 1,
}

export enum AKBreakpoints {
  MOBILE,
  SM,
  MD,
  LG,
  XL,
  XXL,
}

export enum AKBreakpointValues {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
}

export enum AKMessageType {
  INFO,
  WARNING,
  SUCCESS,
  ERROR,
  BE_COMM_ERROR,
}

export type AKTheMainMenuItem = {
  /**
   * Title which will be displayed
   */
  title: string;
  /**
   * name which is an identifier
   */
  name: string;

  /**
   * This are Tags for an advanced search
   * ! NEEDS NAME INCLUDED !
   */
  tags: Array<string>;

  /**
   * This is the Link where the Link will be targeted
   */
  link?: string;

  /**
   * This is the primevue icon class name
   */
  icon?: string;
  /**
   * This is the color for the buttons of the CenteredNav
   */
  color?: string;
};

export type AppNavigationItem = AKTheMainMenuItem & {
  /**
   * List of Items which can be viewed by particular rights
   * "all" means everyone can see this item
   * If it does not exist it means "all"
   */
  viewRights?: Array<string>;
  groupRights?: Array<string>;
  children?: Array<AppNavigationItem>;
  vueComponent?: Component;
  order?: number;
  hideInNav?: boolean;
};

export type AKAppMessage =
  | AKAppMessageSuccess
  | AKAppMessageInfo
  | AKAppMessageWarning
  | AKAppMessageError
  | AKAppMessageBeCommError;

export type AKAppMessageBase = {
  messageId?: number;
  messageSummary?: string;
  messageTranslatableParts?: string[];
  displayTimeInMS?: number;
};

export type AKAppMessageSuccess = AKAppMessageBase & {
  messageType: AKMessageType.SUCCESS;
};

export type AKAppMessageInfo = AKAppMessageBase & {
  messageType: AKMessageType.INFO;
};

export type AKAppMessageWarning = AKAppMessageBase & {
  messageType: AKMessageType.WARNING;
};

export type AKAppMessageError = AKAppMessageBase & {
  messageType: AKMessageType.ERROR;
};

export type AKAppMessageBeCommError = AKAppMessageBase & {
  messageType: AKMessageType.BE_COMM_ERROR;
  errorData: AKBECommErrorResponseSchema;
};

export enum AKAppUIScrollingStates {
  IDLE,
  DOWN,
  UP,
}

export enum AKAppUIScrollingEventType {
  DOWN,
  UP,
  STOP,
}

export type AKAppUIScrollingEvent = {
  event: AKAppUIScrollingEventType;
};

export type AKAppUIStates = {
  out: {
    /**
     * Someone is scrolling in the side panel
     */
    sidePanelScrollingState: AKAppUIScrollingStates;
  };
  in: {
    sidePanelScrollingEvents: Array<AKAppUIScrollingEvent>;
  };
};

/**
 * This is the main State Element
 */
export type AppState = {
  /**
   * This is the Application Name for your App
   * @type {string}
   */
  appName: string;
  /**
   * This is the Version of this Application
   * @example
   * new Version(1, 0, 0)
   */
  version: AKVersionInterface;
  /**
   * This is the height of the application viewport
   */
  viewportHeight: number;

  /**
   * This is the width of the application viewport
   */
  viewportWidth: number;

  /**
   * This is the current breakpoint of the application
   * @example
   * xs, sm, md, lg, xl, xxl
   */
  breakpoint: AKBreakpoints;

  /**
   * This is the current breakpoint of the application
   * @example
   * landscape, portrait
   */
  orientation: AKOrientation;

  /**
   * This is the current Wireframe which is displayed
   */
  currentWireframe: AppWireframes;

  /**
   * How many milliseconds has been pass while loading the app
   */
  loadingTimeInMs: number;
  /**
   * This is the main Navigation menu
   */
  navigationMenu: Array<AppNavigationItem>;
  /**
   * Messages to display
   */
  messages: Array<AKAppMessage>;
  /**
   * This is the Module Title which should be displayed in the header
   */
  currentModuleTitle: string;
  /**
   * True if mobile or tablet
   */
  isMobile: boolean;

  /**
   * pre Login URL
   */
  preLoginURL: string;

  /**
   * This variable is true if the backend is available
   */
  backendAvailable: boolean;

  /**
   * This are UI States to act between independent UI elements
   */
  uiStates: AKAppUIStates;
};
