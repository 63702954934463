<template>
  <AKSelect
    v-model:model-value="currentTournament"
    class="z-module-overlay-layer-flex-2"
    :selectable-options="tournaments"
    :label-cols="2"
    :placeholder="$t('AKSelect.tournament')"
    @on-select="onSelect"
    @on-remove="onRemove">
    <template #singleLabel="{ option }">
      <span>{{ option.name }}</span>
    </template>
    <template #option="{ option }">
      <span>{{ option.name }}</span>
    </template>
    <template #ak-text="{ value }: { value: BECommTournament }">
      <span v-text="`${value.name}`" />
    </template>
  </AKSelect>
</template>

<script setup lang="ts">
import { AKSelect } from '@ak_tools/components/select';
import { computed, inject, ref } from 'vue';

import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';
import { TournamentSelectDataHandler } from '@/components/layout/select/TournamentSelectDataHandler';

import { tournamentStore } from '../../../store/tournaments/index';

//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const dataHandler = inject('TournamentSelectDataHandler') as TournamentSelectDataHandler;
const currentTournamentStore = tournamentStore();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const tournaments = computed(() => dataHandler.data.tournaments);
const currentTournament = computed({
  get: () => {
    return currentTournamentStore.currentTournament;
  },
  set: (tournament: BECommTournament | null) => {
    dataHandler.data.current_tournament = tournament;
    currentTournamentStore.setCurrentTournament(tournament);
  },
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const onSelect = (tournament: BECommTournament) => {
  dataHandler.data.current_tournament = tournament;
  emits('on-tournament-select', tournament);
};

const onRemove = () => {
  dataHandler.data.current_tournament = null;
  emits('on-tournament-clear');
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits(['on-tournament-select', 'on-tournament-clear']);

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
</script>

<style scoped></style>
