<template>
  <div class="border-primary w-8/12 rounded border py-12 text-center">
    <img class="mx-auto mb-4 w-[150px]" src="@ak_tools/assets/images/AKLogo.png" alt="Logo" />
    <h1 class="my-4 text-8xl font-bold">404</h1>
    <p class="my-4 text-3xl">
      <span class="text-red-500">{{ t('AKError404.site-not-found') }}</span>
    </p>
    <p class="my-4 text-4xl">{{ t('AKError404.the-requested-page-does-not-exist') }}</p>
    <AKButton class="w-fit" label="submit">
      <router-link to="/">{{ t('AKError404.main-page') }}</router-link>
    </AKButton>
  </div>
</template>

<script setup lang="ts">
import { AKButton } from '@ak_tools/components/button';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
</script>

<style scoped></style>
