<template>
  <AKWYSIWYGTextReader :text="props.text" :placeholder="props.placeholder" />
</template>

<script setup lang="ts">
interface PropsInterface {
  text?: string | null;
  placeholder?: string;
}
import { AKWYSIWYGTextReader } from '@ak_tools/components/form'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  text: null,
  placeholder: 'AKWYSIWYGTextReader.default-placeholder',
});
</script>

<style lang="sass" scoped></style>
