<template>
  <AKAddButton
    class="button-position"
    :custom-hover="true"
    :class="{ hiddenAddButton: !showButton, shownAddButton: showButton }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"></AKAddButton>
</template>

<script setup lang="ts">
import AKAddButton from '@ak_tools/components/button/AKAddButton.vue';
import { scrollEvent } from '@ak_tools/components/button/AKModuleAddButtonEventBus';
import { onMounted, onUnmounted, ref, watch } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const showButton = ref(true);
let hideTimeout: number | null = null;

const isHovered = ref(false);

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const handleScroll = () => {
  if (showButton.value) {
    showButton.value = false;
  }
  if (hideTimeout !== null) {
    clearTimeout(hideTimeout);
  }
  hideTimeout = window.setTimeout(() => {
    showButton.value = true;
  }, 2000);
};

//  --------------------------------------------------------------------------------------------------------------------
//  watch
//  --------------------------------------------------------------------------------------------------------------------
watch(isHovered, () => {
  if (isHovered.value == true) {
    showButton.value = true;
  }
});
//  --------------------------------------------------------------------------------------------------------------------
//  Events
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  scrollEvent.on(handleScroll);
});

onUnmounted(() => {
  scrollEvent.off(handleScroll);
});
</script>

<style lang="sass" scoped>
.icon-wrapper
  display: inline-flex
  align-items: center
  justify-content: center
  background-color: var(--primary-color)
  border-radius: 50%
  width: 45px
  height: 45px

.button-position
  position: absolute
  right: 35px
  bottom: 45px
  width: max-content
  opacity: 1

.hiddenAddButton
  opacity: 0.1
  transition: opacity 0.2s ease-in-out

  &:hover
    opacity: 1

.shownAddButton
  opacity: 0.7
  transition: opacity 0.2s ease-in-out

  &:hover
    opacity: 1
</style>
