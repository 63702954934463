import { AKBECommPersonEmployeeUserSchema, AKBECommUserGroupSmall } from '../hr/AKBECommPersons';
import { AKBECommDefaultResponse, AKBECommDefaultResponseSchema } from './../../AKBECommDefault';

export enum AKRoleType {
  responsible = 1,
  contributor = 2,
  releaser = 3,
  interested = 4,
  admin = 5,
  maintainer = 6,
  user = 7,
}

export enum AKGroupType {
  wapp = 1,
  personal = 2,
  imported = 3,
}

export enum AKBECommSelectUserGroupFilter {
  all_existing_groups = 'all_existing_groups',
  all_personal_groups = 'all_personal_user_groups',
  all_user_groups_without_personal_groups = 'all_user_groups_without_personal_groups',
  all_my_admin_user_groups = 'all_my_admin_user_groups',
  all_my_admin_and_maintainer_user_groups = 'all_my_admin_and_maintainer_user_groups',
}

export type AKBECommRoleSchema = {
  role_id: number;
  title: string;
  description: string;
};

export type AKBECommUserGroupPersonSchema = AKBECommPersonEmployeeUserSchema & {
  role: AKBECommRoleSchema | null;
  active: boolean;
};

export type AKBECommUserGroupTypeSchema = {
  type_id: number;
  title: string;
  description: string;
};

export type AKBECommUserGroupSourceSchema = {
  source_id: number;
  title: string;
  description: string;
  abbr: string;
  short_abbr: string;
};

export type AKBECommUserGroupChildSchema = {
  user_group_id: number;
  title: string;
  abbr: string;
  description: string;
  source: AKBECommUserGroupSourceSchema | null;
  type: AKBECommUserGroupTypeSchema | null;
  active: boolean;
  display_name: string;
  role: AKBECommRoleSchema | null;
};

export type AKBECommUserGroupMaintainSchema = {
  user_group_id: number;
  title: string;
  abbr: string;
  description: string;
  source: AKBECommUserGroupSourceSchema | null;
  type: AKBECommUserGroupTypeSchema | null;
  active: boolean;
  display_name: string;
  role: AKBECommRoleSchema | null;
};

export type AKBECommUserGroupDirectorySchema = {
  directory_id: number;
  title: string;
  description: string;
  abbr: string;
  short_abbr: string;
  display_name: string;
  display_abbr: string;
  display_short_abbr: string;
  parent_directory: AKBECommUserGroupDirectorySchema | null;
  child_directories: Array<AKBECommUserGroupDirectorySchema>;
  count_of_groups: number;
  groups: Array<AKBECommUserGroupSmall>;
};

export type AKBECommUserGroupSchema = {
  user_group_id: number;
  title: string;
  abbr: string;
  short_abbr: string;
  description: string;
  source: AKBECommUserGroupSourceSchema | null;
  type: AKBECommUserGroupTypeSchema | null;
  active: boolean;
  child_groups: Array<AKBECommUserGroupChildSchema>;
  maintain_groups: Array<AKBECommUserGroupMaintainSchema>;
  parent_group_ids: Array<number>;
  child_group_ids: Array<number>;
  role: AKBECommRoleSchema | null;
  display_name: string;
  display_name_en: string;
  directory: AKBECommUserGroupDirectorySchema | null;
  private: boolean;
  restrict_user_visibility: boolean;
  parent_groups: Array<AKBECommUserGroupSmall>;
};

//  --------------------------------------------------------------------------------------------------------------------
//  user group
//  --------------------------------------------------------------------------------------------------------------------

export type AKBECommUserGroupGetResponse = AKBECommDefaultResponseSchema & {
  user_group: AKBECommUserGroupSchema | null;
};
export type AKBECommUserGroupGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupGetResponse>;

export type AKBECommUserGroupUpdateRequest = {
  display_name: string;
  abbr: string;
  short_abbr: string;
  title: string;
  description: string;
  active: boolean;
  private: boolean;
  restrict_user_visibility: boolean;
  directory_id: number | null;
};
export type AKBECommUserGroupUpdateResponse = AKBECommDefaultResponseSchema & {
  user_group: AKBECommUserGroupSchema | null;
};
export type AKBECommUserGroupUpdateResponseR = AKBECommDefaultResponse<AKBECommUserGroupUpdateResponse>;

export type AKBECommUserGroupDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupDeleteResponseR = AKBECommDefaultResponse<AKBECommUserGroupDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  Maintain user group
//  --------------------------------------------------------------------------------------------------------------------

export type AKBECommMaintainUserGroupUpdateResponse = AKBECommDefaultResponseSchema & {
  user_group_id: number;
  maintain_group: AKBECommUserGroupMaintainSchema | null;
};
export type AKBECommMaintainUserGroupUpdateResponseR = AKBECommDefaultResponse<AKBECommMaintainUserGroupUpdateResponse>;

export type AKBECommMaintainUserGroupDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommMaintainUserGroupDeleteResponseR = AKBECommDefaultResponse<AKBECommUserGroupDeleteResponse>;
//  --------------------------------------------------------------------------------------------------------------------
//  user group Source Type + user group Type
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserGroupSourceTypesGetResponse = AKBECommDefaultResponseSchema & {
  user_group_source_types: Array<AKBECommUserGroupSourceSchema>;
};
export type AKBECommUserGroupSourceTypesGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupSourceTypesGetResponse>;

export type AKBECommUserGroupTypesGetResponse = AKBECommDefaultResponseSchema & {
  user_group_types: Array<AKBECommUserGroupTypeSchema>;
};
export type AKBECommUserGroupTypesGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupTypesGetResponse>;

export type AKBECommUserGroupSourceTypeUpdateRequest = {
  title: string;
  description: string;
  abbr: string;
  short_abbr: string;
};
export type AKBECommUserGroupTypeUpdateRequest = {
  title: string;
  description: string;
};
export type AKBECommUserGroupSourceTypeUpdateResponse = AKBECommDefaultResponseSchema & {
  user_group_source_type: AKBECommUserGroupSourceSchema | null;
};
export type AKBECommUserGroupSourceTypeUpdateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupSourceTypeUpdateResponse>;

export type AKBECommUserGroupSourceTypeGetResponse = AKBECommDefaultResponseSchema & {
  user_group_source_type: AKBECommUserGroupSourceSchema | null;
};
export type AKBECommUserGroupSourceTypeGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupSourceTypeGetResponse>;

export type AKBECommUserGroupTypeUpdateResponse = AKBECommDefaultResponseSchema & {
  user_group_type: AKBECommUserGroupTypeSchema | null;
};
export type AKBECommUserGroupTypeUpdateResponseR = AKBECommDefaultResponse<AKBECommUserGroupTypeUpdateResponse>;

export type AKBECommUserGroupTypeGetResponse = AKBECommDefaultResponseSchema & {
  user_group_type: AKBECommUserGroupTypeSchema | null;
};
export type AKBECommUserGroupTypeGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupTypeGetResponse>;

export type AKBECommUserGroupSourceTypeCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_source_type: AKBECommUserGroupSourceSchema | null;
};
export type AKBECommUserGroupSourceTypeCreateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupSourceTypeCreateResponse>;

export type AKBECommUserGroupTypeCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_type: AKBECommUserGroupTypeSchema | null;
};
export type AKBECommUserGroupTypeCreateResponseR = AKBECommDefaultResponse<AKBECommUserGroupTypeCreateResponse>;

export type AKBECommUserGroupSourceTypeDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupSourceTypeDeleteResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupSourceTypeDeleteResponse>;

export type AKBECommUserGroupTypeDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupTypeDeleteResponseR = AKBECommDefaultResponse<AKBECommUserGroupTypeDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user group directories
//  --------------------------------------------------------------------------------------------------------------------

export type AKBECommUserGroupDirectoriesGetResponse = AKBECommDefaultResponseSchema & {
  user_group_directories: Array<AKBECommUserGroupDirectorySchema>;
};
export type AKBECommUserGroupDirectoriesGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupDirectoriesGetResponse>;

export type AKBECommUserGroupDirectoryUpdateRequest = {
  title: string | null;
  abbr: string | null;
  description: string | null;
  short_abbr: string | null;
  parent_id: number | null;
};

export type AKBECommUserGroupDirectoryUpdateResponse = AKBECommDefaultResponseSchema & {
  user_group_directory: AKBECommUserGroupDirectorySchema | null;
};
export type AKBECommUserGroupDirectoryUpdateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupDirectoryUpdateResponse>;

export type AKBECommUserGroupDirectoryGetResponse = AKBECommDefaultResponseSchema & {
  user_group_directory: AKBECommUserGroupDirectorySchema | null;
};
export type AKBECommUserGroupDirectoryGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupDirectoryGetResponse>;

export type AKBECommUserGroupDirectoryCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_directory: AKBECommUserGroupDirectorySchema | null;
};
export type AKBECommUserGroupDirectoryCreateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupDirectoryCreateResponse>;

export type AKBECommUserGroupDirectoryDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupDirectoryDeleteResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupDirectoryDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user groups
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserGroupsGetRequest = {
  selected_filter: AKBECommSelectUserGroupFilter;
  excluded_user_group_ids: Array<number>;
};

export type AKBECommUserGroupsGetResponse = AKBECommDefaultResponseSchema & {
  user_groups: Array<AKBECommUserGroupSchema>;
};
export type AKBECommUserGroupsGetResponseR = AKBECommDefaultResponse<AKBECommUserGroupsGetResponse>;

export type AKBECommUserGroupsCreateResponse = AKBECommDefaultResponseSchema & {
  user_group: AKBECommUserGroupSchema | null;
};
export type AKBECommUserGroupsCreateResponseR = AKBECommDefaultResponse<AKBECommUserGroupsCreateResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user group con person con role
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserGroupPersonRoleCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_child: AKBECommUserGroupChildSchema | null;
};
export type AKBECommUserGroupPersonRoleCreateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupPersonRoleCreateResponse>;

export type AKBECommUserGroupPersonRoleUpdateRequest = {
  active: boolean;
};
export type AKBECommUserGroupPersonRoleUpdateResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupPersonRoleUpdateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupPersonRoleUpdateResponse>;

export type AKBECommUserGroupPersonRoleDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupPersonRoleDeleteResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupPersonRoleDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user group con parent group
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserGroupParentGroupCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_id: number;
  parent_group: AKBECommUserGroupSchema | null;
};
export type AKBECommUserGroupParentGroupCreateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupParentGroupCreateResponse>;

export type AKBECommUserGroupParentGroupDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupParentGroupDeleteResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupParentGroupDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user group con child group
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserGroupChildGroupCreateResponse = AKBECommDefaultResponseSchema & {
  user_group_id: number;
  child_group: AKBECommUserGroupSchema | null;
};
export type AKBECommUserGroupChildGroupCreateResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupChildGroupCreateResponse>;

export type AKBECommUserGroupChildGroupDeleteResponse = AKBECommDefaultResponseSchema;
export type AKBECommUserGroupChildGroupDeleteResponseR =
  AKBECommDefaultResponse<AKBECommUserGroupChildGroupDeleteResponse>;

//  --------------------------------------------------------------------------------------------------------------------
//  user roles
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommUserRolesGetResponse = AKBECommDefaultResponseSchema & {
  roles: Array<AKBECommRoleSchema>;
};
export type AKBECommUserRolesGetResponseR = AKBECommDefaultResponse<AKBECommUserRolesGetResponse>;

//  --------------------------------------------------------------------------------------------------------------------
// generate and verify user group name
//  --------------------------------------------------------------------------------------------------------------------
export type AKBECommGeneratedUserGroupSchema = {
  display_name: string;
  abbr: string;
  title: string;
  description: string;
  short_abbr: string;
};

export type AKBECommGeneratedUserGroupDirectoryBaseSchema = {
  display_name: string;
  abbr: string;
  title: string;
  description: string;
  short_abbr: string;
};
export type AKBECommGeneratedUserGroupDirectorySchema = AKBECommGeneratedUserGroupDirectoryBaseSchema & {
  new_generated_top_directories: Array<AKBECommGeneratedUserGroupDirectoryBaseSchema>;
};
export type AKBECommGenerateAndVerifyUserGroupNameRequest = {
  prompt: string;
  directory_id: number;
};

export type AKBECommGenerateAndVerifyUserGroupDirectoryNameRequest = {
  prompt: string;
  directory_id: number;
};

export type AKBECommGenerateAndVerifyUserGroupNameResponse = AKBECommDefaultResponseSchema & {
  generated_user_groups: AKBECommGeneratedUserGroupSchema[];
  text: string | null;
};

export type AKBECommGenerateAndVerifyUserGroupDirectoryNameResponse = AKBECommDefaultResponseSchema & {
  generated_user_group_directories: AKBECommGeneratedUserGroupDirectorySchema[];
  text: string | null;
};
export type AKBECommGenerateAndVerifyUserGroupNameResponseR =
  AKBECommDefaultResponse<AKBECommGenerateAndVerifyUserGroupNameResponse>;

export type AKBECommGenerateAndVerifyUserGroupDirectoryNameResponseR =
  AKBECommDefaultResponse<AKBECommGenerateAndVerifyUserGroupDirectoryNameResponse>;

//  ------------------------------------------------------------------------------------------------------------------
//  duplicate user group
//  ------------------------------------------------------------------------------------------------------------------
export type AKBECommDuplicateUserGroupCreateRequest = {
  user_group_id: number;
};

export type AKBECommDuplicateUserGroupCreateResponse = AKBECommDefaultResponseSchema & {
  user_group: AKBECommUserGroupSchema | null;
};
export type AKBECommDuplicateUserGroupCreateResponseR =
  AKBECommDefaultResponse<AKBECommDuplicateUserGroupCreateResponse>;
