//  --------------------------------------------------------------------------------------------------------------------
//                                            ABUS Kransysteme GmbH
//  --------------------------------------------------------------------------------------------------------------------
/**
 * @overview: This are the types of the user.
 * @author: CHB0974
 *
 * Version  Date       Name                              Description
 * ======== ========== ================================= ===============================================================
 * 1.0.0    26.04.2022 CHB0974                           Created
 */

import { AKVersionInterface } from '@ak_tools/ts_modules/core/AKVersion';

//  ----------------------------------------------------------------------------
//  Imports
//  ----------------------------------------------------------------------------

//  --------------------------------------------------------------------------------------------------------------------
//  Types
//  --------------------------------------------------------------------------------------------------------------------
/**
 * This is the LoginState to know if the user is logged In or Out
 */
export enum LoginState {
  LoggedOut = 0,
  KeycloakLoginPending = 2,
  KeycloakAccessFailed = 3,
  PortalLoginPending = 4,
  PortalLoginFailed = 5,
  LoggedIn = 99,
}

export type Person = {
  personId: number;
  firstname: string;
  lastname: string;
  gender: string;
  title: string;
  personalNumber: string;
  employeeOrganizationalUnitAbbr: string;
  employeeOrganizationalUnitTitle: string;
  picture: string;
};

export type UserGroup = {
  userGroupId: number;
  title: string;
  description: string;
  abbr: string;
  private: boolean;
};

export type ApplicationModule = {
  moduleId: number;
  title: string;
};

export type Application = {
  uuid: string;
  title: string;
  version: AKVersionInterface;
  modules: Array<ApplicationModule>;
  modulesTitles: Array<string>;
};

/**
 * This is the main State Element
 */
export interface UserState {
  /**
   * This is the User Id
   * @type {string}
   * @example
   * 0974
   */
  userId: number;
  /**
   * This is the Name of the User
   * @example
   * BuehneCh0974
   */
  userName: string;

  /**
   * personal group of the user
   */
  personalUserGroupId: number;

  /**
   * groups of the user
   */
  userGroups: Array<UserGroup>;

  /**
   * group titles of the user for quick requesting the titles
   */
  userGroupsTitles: Array<string>;

  /**
   * here we can derive the module rights
   */
  application: Application;

  /**
   * Firstname of the user
   */
  person: Person;

  /**
   * ist the user logged in ?
   */
  loginState: LoginState;

  /**
   * email of the user
   */
  eMail: string;
}
