<template>
  <AKModuleContainer ref="akModuleContainer">
    <template #moduleHeaderActions>
      <GeneralTournamentSelect
        class="z-module-layer-flex-2"
        @on-tournament-select="updateCurrentTournament"
        @on-tournament-clear="clearCurrentTournament" />
    </template>
    <template #grid>
      <AKLoadingContent
        v-if="dataHandler.data.elementsAreLoading"
        class="ak-container-main-overlay-default z-module-overlay-layer" />
      <LocationGrid @on-card-click="addItemIdToRoute" />
    </template>

    <template #sidePanel>
      <AKLoadingContent
        v-if="dataHandler.data.elementIsLoading"
        class="ak-container-main-overlay-default z-module-overlay-layer" />
      <ReservationDataSidePanel
        @close-side-panel="closeSidePanel"
        @maximize-side-panel="maximizeSidePanel"
        @minimize-side-panel="minimizeSidePanel" />
    </template>
  </AKModuleContainer>
</template>

<script setup lang="ts">
import AKModuleContainer from '@ak_tools/components/layout/AKModuleContainer.vue';
import { AKLoadingContent } from '@ak_tools/components/overlay';
import { AKStore } from '@ak_tools/start/App';
import { computed, onMounted, provide, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import { BECommLocation } from '@/backend_communication/definitions/BECommLocations';
import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';
import GeneralTournamentSelect from '@/components/layout/select/GeneralTournamentSelect.vue';
import { TournamentSelectDataHandler } from '@/components/layout/select/TournamentSelectDataHandler';
import LocationGrid from '@/components/reservations/LocationGrid.vue';
import { ReservationDataHandler } from '@/components/reservations/ReservationDataHandler';
import ReservationDataSidePanel from '@/components/reservations/ReservationDataSidePanel.vue';

import { tournamentStore } from '../../store/tournaments/index';

const { locale } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const dataHandler = ref(new ReservationDataHandler());
const akModuleContainer = ref<InstanceType<typeof AKModuleContainer> | null>(null);
const router = useRouter();
const route = useRoute();
const currentRouteName = String(route.name);
const tournamentSelectDataHandler = ref(new TournamentSelectDataHandler());
const currentTournamentStore = tournamentStore();

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const currentRouterItemId = computed(() => {
  return Number(route.params.item_id);
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const closeSidePanel = () => {
  resetView();
  dataHandler.value.data.current_location = null;
};

const maximizeSidePanel = () => {
  akModuleContainer.value?.maximizeSidePanel();
};

const minimizeSidePanel = () => {
  akModuleContainer.value?.minimizeSidePanel();
};

const addItemIdToRoute = (location: BECommLocation) => {
  if (currentRouterItemId.value !== location.location_id) {
    router.push({
      params: { item_id: location.location_id },
      query: route.query,
    });
  }
  dataHandler.value.data.current_location = location;
};

const resetView = () => {
  akModuleContainer.value?.closeSidePanel();

  dataHandler.value.data.current_reservation = null;
  updateRoute(true);
};
const updateRoute = (resetView = false) => {
  const reservation_id = resetView ? 0 : currentRouterItemId.value || 0;

  router.push({ name: currentRouteName, params: { item_id: reservation_id } });
};

const showSidePanel = () => {
  akModuleContainer.value?.showSidePanel();
};

const updateCurrentItem = async () => {
  if (currentRouterItemId.value > 0) {
    await openItemById(currentRouterItemId.value);
  }
};

const openItemById = async (id: number) => {
  showSidePanel();
  if (id !== dataHandler.value.data.current_location?.location_id) {
    dataHandler.value.data.current_location =
      dataHandler.value.data.locations.find((l) => l.location_id === id) || null;
  }
};

const updateCurrentTournament = (tournament: BECommTournament) => {
  dataHandler.value.data.current_tournament = tournament;
  closeSidePanel();
};

const clearCurrentTournament = () => {
  dataHandler.value.data.current_tournament = null;
  closeSidePanel();
};

const setModuleTitle = () => {
  AKStore.App.state.currentModuleTitle = 'Belegungsplan';
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(currentRouterItemId, () => {
  updateCurrentItem();
});

watch(locale, () => {
  setModuleTitle();
});
watch(
  () => dataHandler.value.data.current_tournament,
  async (newTournament) => {
    dataHandler.value.data.participants = [];
    dataHandler.value.data.current_reservation = null;
    dataHandler.value.data.current_match_to_reserve = null;
    dataHandler.value.data.current_timeslots = [];
    dataHandler.value.data.locations = [];
    dataHandler.value.data.matches = [];
    dataHandler.value.data.reservations = [];
    if (newTournament) {
      await dataHandler.value.getParticipantsByTournamentFromBackend(newTournament.tournament_id);
      await dataHandler.value.getLocationsByTournamentFromBackend();
      await dataHandler.value.getMatchesFromBackend();
      await dataHandler.value.getTimeslotsByMatchDurationFromBackend();
      await dataHandler.value.getReservationsFromBackend();
      await updateCurrentItem();
    } else {
      dataHandler.value.data.matches = [];
    }
  },
);
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('TournamentSelectDataHandler', tournamentSelectDataHandler.value);
provide('ReservationDataHandler', dataHandler.value);

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(async () => {
  await tournamentSelectDataHandler.value.fetchTournaments();
  if (!currentTournamentStore.currentTournament) {
    currentTournamentStore.currentTournament = tournamentSelectDataHandler.value.data.tournaments[0];
  }
  dataHandler.value.data.current_tournament = currentTournamentStore.currentTournament;
  dataHandler.value.getAllTimeSlotsFromBackend();
});

setModuleTitle();
</script>

<style lang="sass" scoped></style>
