<template>
  <div class="flex flex-wrap items-center gap-2" tabindex="0">
    <AKTag v-for="userGroup in props.userGroups" :key="userGroup.user_group_id">
      <span
        v-if="userGroup.private && (userGroup.display_name === null || userGroup.display_name === '')"
        v-text="t('AKEmployeeCard.private')" />
      <span v-else v-text="userGroup.display_name" />
    </AKTag>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  userGroups: AKBECommUserGroupSchema[];
}
import { AKBECommUserGroupSchema } from '@ak_tools/backend_communication/definitions/users/AKBECommUsers'
import { AKTag } from '@ak_tools/components/misc'
import { useI18n } from 'vue-i18n'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps<PropsInterface>();
const { t } = useI18n();
</script>

<style lang="sass" scoped></style>
