<template>
  <div v-if="false" />
</template>

<script setup lang="ts">
interface PropsInterface {
  fittyCss?: string;
}
import { AKStore } from '@ak_tools/start/App'
import { AKAppUIScrollingEvent, AKAppUIScrollingEventType, AKAppUIScrollingStates, AKBreakpoints } from '@ak_tools/store/app/types'
import fitty from 'fitty'
import { isMobile } from 'mobile-device-detect'
import { onBeforeUnmount, onMounted, watch, watchEffect } from 'vue'
class AKFitTextToParent {
  public load(cssClass = '.fitText') {
    fitty(cssClass, {
      minSize: 12,
      maxSize: 9000,
      observeMutations: {
        subtree: true,
        childList: true,
        characterData: true,
      },
    });
  }
}
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  fittyCss: '.ak-fit-text',
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const appFitty = new AKFitTextToParent();
const breakpointValues = { SM: 640, MD: 768, LG: 1024, XL: 1280, XXL: 1536 };
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const refreshLayoutElements = (timeout = 100, retries = 5) => {
  return new Promise((resolve) => {
    appFitty.load(props.fittyCss);
    for (let i = 0; i < retries; i++) {
      setTimeout(() => {
        appFitty.load(props.fittyCss);
      }, timeout);
    }
    resolve({});
  });
};
const setBreakpoint = () => {
  const width = AKStore.App.state.viewportWidth;
  let breakpoint: AKBreakpoints = AKBreakpoints.MOBILE;
  if (width >= breakpointValues.SM && width < breakpointValues.MD) {
    breakpoint = AKBreakpoints.SM;
  } else if (width >= breakpointValues.MD && width < breakpointValues.LG) {
    breakpoint = AKBreakpoints.MD;
  } else if (width >= breakpointValues.LG && width < breakpointValues.XL) {
    breakpoint = AKBreakpoints.LG;
  } else if (width >= breakpointValues.XL && width < breakpointValues.XXL) {
    breakpoint = AKBreakpoints.XL;
  } else if (width >= breakpointValues.XXL) {
    breakpoint = AKBreakpoints.XXL;
  }
  AKStore.App.state.breakpoint = breakpoint;
};
const checkViewportWidth = () => {
  AKStore.App.state.viewportWidth = window.innerWidth;
  setBreakpoint();
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(
  [() => AKStore.App.state.currentWireframe],
  () => {
    refreshLayoutElements();
  },
  { immediate: true },
);
const sidePanelStateMachineLoop = (event: AKAppUIScrollingEvent) => {
  switch (AKStore.App.state.uiStates.out.sidePanelScrollingState) {
    case AKAppUIScrollingStates.IDLE: {
      if (event.event === AKAppUIScrollingEventType.DOWN) {
        AKStore.App.state.uiStates.out.sidePanelScrollingState = AKAppUIScrollingStates.DOWN;
      } else if (event.event === AKAppUIScrollingEventType.UP) {
        AKStore.App.state.uiStates.out.sidePanelScrollingState = AKAppUIScrollingStates.UP;
      } else if (event.event === AKAppUIScrollingEventType.STOP) {
        AKStore.App.state.uiStates.out.sidePanelScrollingState = AKAppUIScrollingStates.IDLE;
      }
      break;
    }
    case AKAppUIScrollingStates.DOWN:
      AKStore.App.state.uiStates.out.sidePanelScrollingState = AKAppUIScrollingStates.IDLE;
      sidePanelStateMachineLoop(event);
      break;
    case AKAppUIScrollingStates.UP:
      AKStore.App.state.uiStates.out.sidePanelScrollingState = AKAppUIScrollingStates.IDLE;
      sidePanelStateMachineLoop(event);
      break;
  }
};
watchEffect(() => {
  const events = AKStore.App.state.uiStates.in.sidePanelScrollingEvents;
  events.filter(Boolean).forEach((event) => {
    sidePanelStateMachineLoop(event);
    AKStore.App.removeSidePanelScrollingEvent(event);
  });
});
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  checkViewportWidth();
  window.addEventListener('resize', checkViewportWidth);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', checkViewportWidth);
});
AKStore.App.state.isMobile = isMobile;
</script>

<style lang="sass" scoped></style>
